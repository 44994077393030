import React from "react";

import ProgramList from './ProgramList';

export default function Program( {staticData, textConfig} ) {

  const programLists = staticData.program.map( item => {
    return <ProgramList key={ item.type } staticData={ item } textConfig={textConfig} />
  })

  return(
    <section id="program" className="section-program">
    <div className="container">
      <h2 data-aos-duration="1200" data-aos="fade-right" className="aos-init">{ staticData.title }</h2>
      <div data-aos-duration="1200" data-aos="fade-right" className="aos-init program-text" dangerouslySetInnerHTML={{__html: staticData.text}}>
      </div>
      {/* <ul data-aos-duration="1200" data-aos="fade-right" className="program-controls aos-init">
        { staticData.navigation.map((nav, index) => {
          return <li key={index}>
            <a href={nav.btn.url}><button className="btn-program">{ nav.btn.label }</button></a>
          </li>
        })}
      </ul> */}

      <div className="program-wrapper">

        { programLists }

      </div>

      {/* <div className="program-more">
        <div className="btn-hero"><a href="/">MAPA PREDNAŠKOVÝCH MÍSTNOSTÍ</a></div>
      </div> */}
    </div>
  </section>
  )
}