import React from "react";

export default function Contact( { staticData }) {
  return(
    <section id="contact" className="section-contact">
    <div className="container">
      <div className="contact-info">
        <div className="contact-col">
          <h2>{ staticData.label }</h2>
          <h3>{ staticData.question }</h3>
            <div className="contact-text" dangerouslySetInnerHTML={{__html: staticData.text}}></div>
          {staticData.mail != null && <a href={`mailto:${staticData.mail}`}>{staticData.mail}</a>}
          <p dangerouslySetInnerHTML={{__html: staticData.address}}>
          </p>
          <h3>{ staticData.textBottom }</h3>
          {/* <h3>Jak se k nám dostanete?</h3> */}
          {/* <p>Najdete nás na adrese Bubenská 1, 170 00 Praha 7, prímo
            U metra Vltavská.</p>
          <a href="/">Navigovat do WPP Campusu</a> */}
        </div>
        <div className="contact-col">
          <div className="contact-image">
            <img src="/assets/images/contact.jpeg" alt="WPP"/>
          </div>
        </div>
      </div>
      <div className="contact-map">
        <iframe src={staticData.map} width="100%" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  </section>
  )
}