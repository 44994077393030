const data = {
  pages: {
    homepage: {
      hero: {
        videoUrl: "../assets/images/campus.mp4",
        logo: "WPP",
        logoText: "EXPO",
        text: "Worlds of Marketing",
        date: "21. červen 2023",
        btn: {
          label: "REGISTRUJTE SE",
          url: "https://www.eventbrite.com/e/wpp-expo-worlds-of-marketing-tickets-577032548887"
        }
      },
      countdown: {
        label: "Pro vstup do 9 úžasných světů marketingu",
        labelBottom: "Zbývá do dne, kdy se vám otevře 9 úžasných světů za hranicemi všedního marketingu",
        time: {
          day: "dní",
          hour: "hodin",
          minute: "minut",
          second: "sekund"
        }
      },
      about: {
        side: "WPP EXPO",
        title: "Vzhůru za hranice všedního marketingu",
        text: "Naplánujte si objevnou misi 9 světy marketingu. Inspirujte se přednáškami pozoruhodných řečníků. Zmapujte mediální a technologické novinky na stáncích partnerů. Bavte se na doprovodných akcích. Myslete na rozlehlou terasu WPP Campusu. Má úchvatné panorama,  super vibe, svědčí networkingu a spolu s BBQ party umocňuje vše, co vám Worlds of Marketing přinese."
      },
      program: {
        title: "Program",
        text: "World of Brand & Strategy, World of Consumer & Insights, World of Future a 6 dalších úžasných světů, přednášky pozoruhodných osobností, expozice médií a technologií plus spousta příležitostí  k networkingu na doprovodných akcích.",
        navigation: [
          {
            btn: {
              label: "Světy marketingu",
              url: "talks"
            }
          },
          {
            btn: {
              label: "VELETRŽNÍ Expozice",
              url: "expo"
            }
          },
          {
            btn: {
              label: "Doprovodné akce",
              url: "others"
            }
          }
        ],
        program: [
            {
            type: "Přednášky",
            id: "talks",
            items: [
              {
                place: "Posluchárna, Mezanin",
                time: "9:30 – 18:00",
                label: "SÉRIE PŘEDNÁŠEK NA MARKETINGOVÁ TÉMATA",
                text: "Připravujeme pro vás line-up nabitý pozoruhodnými řečníky. O tématech přednášek vás budeme brzy informovat.",
                link: {
                  text: "Přehled řečníků",
                  url: "#speakers"
                }
              },
            ]
          },
          {
            type: "Expozice",
            id: "expo",
            items: [
              {
                place: "Krmeetko",
                time: "9:00-18:00",
                label: "VELETRŽNÍ EXPOZICE",
                text: "Zmapujte mediální a technologické novinky v atraktivních expozicích partnerů WPP EXPO.",
                link: {
                  text: "Přehled partnerů",
                  url: "#partners"
                }
              },
              {
                place: "Krmeetko",
                time: "9:00-18:00",
                label: "SVĚTY MARKETINGU",
                text: "",
                link: {
                  text: "Světy marketingu",
                  url: "#stands"
                }
              },
            ]
          },
          {
            type: "Další aktivity",
            id: "others",
            items: [
              {
                time: "11:00, 14:00, 16:00",
                label: "KOMENTOVANÉ PROHLÍDKY CAMPUSU",
                text: "Odhalte tajemství budovy. Připojte se na komentovanou prohlídku s Davidem Lhotou a architektem Markem Tichým. ",
              },
              {
                time: "11:00, 14:00",
                label: "VÝPRAVA DO VÝZKUMNÉ KUCHYNĚ",
                text: "Podívejte se do moderního focus group studia, kde nejlepší výzkumníci dolují hluboké insighty.",
              }
            ]
          }
        ]
      },
      stands: {
        label: "Světy marketingu",
        text: "Vydejte se na objevnou misi 9 světy marketingu",
        stands: [
          {
            label: "World of Brand & Strategy",
            text: "Postrádá vaše značka lesk? Pojďte se podívat do světa, který vám ukáže, jak ji dostat na výsluní, nablýskat a zkrátka zařídit, že ji ještě dlouho nikdo nepřehlédne. Nechte svůj brand zářit!",
            class: "stands-bg--brand"
          },
          {
            label: "World of Consumer & Insights",
            text: "Svět zákazníků, uživatelů a spotřebitelů. To je nekonečný příběh objevování, co si tito lidé myslí, po čem touží, jak se chovají a co formuje jejich názory. Proč mají raději značku A než X, na jaké argumenty slyší a čemu věří? Zjistěte to s námi.",
            class: "stands-bg--consumer"
          },
          {
            label: "World of Future",
            text: "Jak moc změní AI marketing? Proč by měl blockchain zajímat marketéry? Jak vypadá hybridní realita? A proč byste vlastně měli tyto otázky řešit? Na to všechno vám odpovíme ve světě budoucnosti.",
            class: "stands-bg--future"
          },
          {
            label: "World of Data & Analytics",
            text: "Zajímá vás, jak lépe poznat své zákazníky a pochopit jejich chování a postoje v online i offline světě? Ukážeme vám, jak analyzujeme, interpretujeme a vizualizujeme zákaznická data, na jejichž základě můžete predikovat jejich budoucí chování.",
            class: "stands-bg--data"
          },
          {
            label: "World of Media",
            text: "Otřepaná fráze říká, že „obsah je král“. Ovšem i ta nejlepší reklama postrádá svůj smysl, pokud ji nikdo nevidí. Je jako císařovy nové šaty. Tento svět se proto bude plně věnovat královně komunikace - médiím a mediální strategii.",
            class: "stands-bg--media"
          },
          {
            label: "World of Personalisation",
            text: "Zákazník si přeje, abychom mu rozuměli. Díky personalizaci to dnes nejen že zvládáme, ale přetváříme způsob oslovování a udržování vztahů se zákazníky. Objevte sílu cíleného přístupu. Věnujte se personalizaci a dosáhnete úspěchu!",
            class: "stands-bg--person"
          },
          {
            label: "World of Creativity",
            text: "Kreativita. Záhadné zaklínadlo? A co si pod tím představit? Tento svět vás provede kreativním procesem, dokáže, že kreativita pomáhá prodávat, a inspiruje vás, jak mohou značky pomocí kreativity měnit svět k lepšímu.",
            class: "stands-bg--creativity"
          },
          {
            label: "World of Content Marketing",
            text: "Jak připravit nejlepší obsah pro integrovanou kampaň, sociální sítě, organické i placené PR a navíc to mít díky SEO vše perfektně optimalizované? My obsáhneme všechno!",
            class: "stands-bg--content"
          },
          {
            label: "World of ESG",
            text: "Nerovné příležitosti, klimatická krize nebo válka. Značky a organizace čelí výzvám a rozhodnutím, které mají zásadní dopady na náš svět. Jak autenticky přistoupit k tématu ESG a vydat se na cestu udržitelného růstu?",
            class: "stands-bg--sustain"
          }
        ]
      },
    },
    speakerspage: {
        title: "Řečníci WPP EXPO",
        text: "Inspirativní osobnosti českého marketingu se podělí o zkušenosti a představí svůj pohled na nejzajímavější marketingové trendy.<br /><br />Potkáte se mimo jiné s těmito experty:",
        text_bottom: "Další řečníky brzy představíme",
        items: [
          {
            name: "Jan Los XX",
            text: "Chief Marketing & Digital Officer<br />L'Oréal",
            class: "speaker-bg--brand",
            //delay: 0,
            image: {
              url: "assets/images/Jan_Los.jpg"
            }
          },
          {
            name: "Mary Kyriakidi",
            text: "Global Thought Leader<br />Kantar",
            class: "speaker-bg--consumer",
            //delay: 300,
            image: {
              url: "assets/images/Mary_Kyriakidi.jpg"
            }
          },
          {
            name: "Ladislav Báča",
            text: "Director of Brand & Marketing Communication<br />T-Mobile CZ",
            class: "speaker-bg--future",
            //delay: 600,
            image: {
              url: "assets/images/Ladislav_Baca.jpg"
            }
          },
          {
            name: "Jan Los 2",
            text: "Chief Marketing & Digital Officer<br />L'Oréal",
            class: "speaker-bg--brand",
            //delay: 0,
            image: {
              url: "assets/images/Jan_Los.jpg"
            }
          },
          {
            name: "Mary Kyriakidi 2",
            text: "Global Thought Leader<br />Kantar",
            class: "speaker-bg--consumer",
            //delay: 300,
            image: {
              url: "assets/images/Mary_Kyriakidi.jpg"
            }
          },
          {
            name: "Ladislav Báča 2",
            text: "Director of Brand & Marketing Communication<br />T-Mobile CZ",
            class: "speaker-bg--future",
            delay: 600,
            image: {
              url: "assets/images/Ladislav_Baca.jpg"
            }
          }
        ]
    }
  },

  header: {
    logo: {
      url: "/assets/images/WPP-logo.svg",
    },
    links: [
      {
        url: "#about",
        text: "O WPP EXPO"
      },
      {
        url: "#program",
        text: "Program"
      },
      {
        url: "#stands",
        text: "Světy marketingu"
      },
      {
        url: "/speakers",
        text: "Řečníci"
      },
      {
        url: "#contact",
        text: "Kontakt"
      },
      {
        url: "#partners",
        text: "Partneři"
      },
      {
        url: "/en",
        text: "EN",
        navlink: true,
      },
      {
        url: "https://www.eventbrite.com/e/wpp-expo-worlds-of-marketing-tickets-577032548887",
        text: "Registrace",
        class: "btn-nav",
        target: "_blank"
      }
    ]
  },
  register: {

  },
  partners: {
    label: "Partneři WPP EXPO",
    text: "Partneři",
    partners: [
      {
        label: "Hlavní partner",
        items: [
          {
            img: "../assets/images/logo/nova.svg"
          }
        ]
      },
      {
        label: "Partneři akce",
        items: [
          {
            img: "../assets/images/logo/adobe.svg"
          },
          {
            img: "../assets/images/logo/bigmedia.png"
          },
          {
            img: "../assets/images/logo/cnc.png"
          },
          {
            img: "../assets/images/logo/seznam.svg"
          },
          {
            img: "../assets/images/logo/bloomreach.png"
          },
          {
            img: "../assets/images/logo/salesforce.png"
          },
          {
            img: "../assets/images/logo/tiktok.png"
          },
        ]
      },
      {
        label: "O osvěžení návštěvníků se starají",
        items: [
          {
            img: "../assets/images/logo/cocacola.png"
          }
        ]
      },
      {
        label: "Dopravu návštěvníků zajistí",
        items: [
          {
            img: "../assets/images/logo/liftago.png"
          }
        ]
      }
    ]
  },
  contact: {
    label: "Kontakt",
      mail: "wppexpo@wpp.com",
    question: "Máte dotaz?",
    address: "WPP EXPO: Worlds of Marketing<br />Bubenská 1<br />170 00  Praha 7<br />wpp EXPO@wpp.com",
    text: "Klienti agentur ze skupiny WPP se mohou obracet přímo na svého account managera.",
    textBottom: "Těšíme se na vás v paláci bývalých Elektrických podniků (dnes WPP Campus) přímo u stanice metra Vltavská.",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2559.328504258991!2d14.4369503!3d50.0988579!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b95251e6453f5%3A0x3fee3b9c0355374b!2sWPP%20Campus!5e0!3m2!1scs!2scz!4v1681220567590!5m2!1scs!2scz"
   },
  footer: {
    items: [
      {
        url: "https://www.eventbrite.com/e/wpp-expo-worlds-of-marketing-tickets-577032548887",
        text: "Registrace",
        target: "_blank"
      },
      {
        url: "#speakers",
        text: "Přednášející"
      },
      {
        url: "#program",
        text: "Program"
      },
      {
        url: "#partners",
        text: "Partneři"
      },
      {
        url: "#stands",
        text: "Světy marketingu"
      },
      {
        url: "#contact",
        text: "Kontakt"
      }
    ]
  }
  
}

export default data;