import React, { useEffect, useState, useRef } from "react";
import AOS from 'aos';

import fetchApi, { hashValue, formatDate, setFileFromUUID, setProgramCategoryFromUUID, setRowFromUUID} from '../api.js';
import { useLoaderData, useNavigate} from "react-router-dom";
import textConfig from "../textConfig";
import staticDataJSEN from "../staticDataEN";
import ProgramVideoList from "../components/ProgramVideoList";

import Cookies from 'js-cookie';


function prepareData(content,textConfigLocal={}){
    let returned_data = {}

    let paragraphs = content[0].field_paragraphs;
    paragraphs.forEach(function (item){
        switch (item.field_display_style){
            case 'program':
                let categories = [];
                item.program_rows.forEach(function(program_row_item){
                    let program_row = setRowFromUUID(program_row_item.id, content.program_rows);
                    let category = program_row.category;
                    if( typeof categories[category] == 'undefined' ){
                        categories[category] = [];
                    }
                    categories[category].push(program_row);
                });

                let program = [];
                let navigation = [];
                for (const [category, rows] of Object.entries(categories)) {
                    const category_items = [];
                    rows.forEach(function(row){
                        if( row?.detail != undefined ) {
                            category_items.push({
                                place: row.place,
                                time: row.time,
                                label: row.title,
                                text: row.body,
                                detail: row.detail,
                                link: {
                                    text: row.link?.title,
                                    url: row.link?.uri.replace('internal:', '')
                                }
                            });
                        }
                    });
                    if( category_items.length > 0 ) {
                        let category_name = setProgramCategoryFromUUID(category,content.program_categories);
                        program.push({
                            type: category_name,
                            type_id: category,
                            items: category_items
                        });

                        navigation.push({
                            btn: {
                                label: category_name,
                                url: "#" + category
                            }
                        });
                    }
                }
                returned_data.program = {
                    title: item.field_title,
                    text: item.body,
                    program: program,
                    navigation: navigation
                };
                break;

        }
    });
    return returned_data;
}

export async function loader ({params}) {
    let lang = 'cs';
    let textConfigLocal = textConfig[lang];
    if( params.hasOwnProperty('lang') ){
        if( params.lang == 'en' ){
            lang = params.lang;
            textConfigLocal = textConfig[lang];
        }
    }

    let content = await fetchApi({type: 'programpage', lang: lang});
    content = prepareData(content, textConfigLocal);
    return { content: content, lang: lang, textConfig: textConfigLocal.recordings };
    
}

export default function ProgramPage({data}) {
    const dataArray = useLoaderData();
    const content_data = dataArray.content.program;
    const textConfig = dataArray.textConfig;

    const [ loggedIn, setLoggedIn] = useState(false);
    const [ wrongPass, setWrongPass] = useState(false);
    const inputRef = useRef('');
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init();
        checkPassoword();
    }, [])

    const checkPassoword = (e) => {
        let currentPass = Cookies.get(hashValue('wppexpo_pass'));
  
        if( currentPass == process.env.REACT_APP_PASS) {
          Cookies.set(hashValue('wppexpo_pass'), currentPass, {expires: 1});
          setLoggedIn(true);
        }

        if( hashValue(inputRef.current.value) == process.env.REACT_APP_PASS) {
          Cookies.set(hashValue('wppexpo_pass'), hashValue(inputRef.current.value), {expires: 1});
          setLoggedIn(true)
        }
        
        if( hashValue(inputRef.current.value) != '' &&  hashValue(inputRef.current.value) != process.env.REACT_APP_PASS ) {
          setWrongPass(true);
        }
      }

      const checkKey = (e) => {
        if( e.key == "Enter") {
          checkPassoword();
        }
      }

    const programLists = content_data.program.map( item => {
        return <ProgramVideoList key={ item.type } staticData={ item } />
    })

    if( loggedIn ) {
        return(
            <section id="program" className="section-program">
                <div className="container">
                    <h2 data-aos-duration="1200" data-aos="fade-right" className="aos-init">{ textConfig.title }</h2>
                    { content_data.navigation.length > 1 &&
                    <ul data-aos-duration="1200" data-aos="fade-right" className="program-controls aos-init">
                    { content_data.navigation.map((nav, index) => {
                        return <li key={index}>
                            <a href={nav.btn.url}><button className="btn-program">{ nav.btn.label }</button></a>
                        </li>
                        })}
                    </ul>
                    }

                    <div className="program-wrapper">

                        { programLists }

                    </div>

                </div>
            </section>
        )
    }
    else {
        return (
            <section id="program" className="section-program section-videos">
            <div className="container">
                <h1>{ textConfig.title }</h1>
                <h2>{ textConfig.enterPassword }</h2>
                <div className="form-input-text">
                  <input onKeyUp={ checkKey } ref={inputRef} type="password" />
                  { wrongPass && <div className="input-error">{ textConfig.wrongPassword }</div>}
                </div>
                <div className="buttons">
                  <div onClick={ checkPassoword } className="btn-hero"><span>{ textConfig.send }</span></div>
                  {/* <div onClick={() => navigate(-1)} className="btn-hero"><span>{ textConfig.back }</span></div> */}
                </div>
                </div>
            </section>
        )
    }

}