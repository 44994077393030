import React from "react";
import PartnersList from "./PartnersList";

export default function Partners( {staticData} ) {

  const partnersLists = staticData.partners.map( (item, index) => {
    return <PartnersList key={ index } staticData={ item } />
  })

  return(
    <section id="partners" className="section-partners">
    <div className="container">
      <h2>{staticData.label}</h2>

      { partnersLists }

    </div>
  </section>
  )
}