import React, { useEffect, useState, useRef } from "react";
import AOS from 'aos';

import { useLoaderData, useNavigate } from "react-router-dom";

import textConfig from "../textConfig";
import fetchApi, {setFileFromUUID, hashValue} from "../api";
import ErrorPage from "./ErrorPage";

import Cookies from 'js-cookie';

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

function prepareData(content,textConfigLocal){
    let returned_data = {}

    returned_data = content[0];
    returned_data.images = [];
    content[0].field_paragraphs.forEach(function(item){
        item.images.forEach(function (image_item){
            returned_data.images.push({
                id: image_item.id,
                title: image_item.meta?.title,
                thumbnail: setFileFromUUID(image_item.id, content.files, ['url'], 'medium').url,
                wide: setFileFromUUID(image_item.id, content.files, ['url'], 'wide').url
            });
        })
    });
    return returned_data;
}

export async function loader ({params}) {

    let lang = 'cs';
    if( params.hasOwnProperty('lang') ){
        if( params.lang == 'en' ){
            lang = params.lang;
        }
    }
    let textConfigLocal = textConfig[lang];
    try {
        let content = await fetchApi({ type: 'photos' , lang: lang });
        content = prepareData(content,textConfigLocal);
        content.textConfig = textConfigLocal;
        return { content_data: content, textConfig: textConfigLocal.recordings};
    } catch (e){
        return e;
    }
}


export default function PhotogalleryPage( {data} ) {
    const [index, setIndex] = useState(-1);

    const [ loggedIn, setLoggedIn] = useState(false);
    const [ wrongPass, setWrongPass] = useState(false);
    const inputRef = useRef('');
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init();
        checkPassoword();
    }, [])

    const checkPassoword = (e) => {
        let currentPass = Cookies.get(hashValue('wppexpo_pass'));
  
        if( currentPass == process.env.REACT_APP_PASS) {
          Cookies.set(hashValue('wppexpo_pass'), currentPass, {expires: 1});
          setLoggedIn(true);
        }

        if( hashValue(inputRef.current.value) == process.env.REACT_APP_PASS) {
          Cookies.set(hashValue('wppexpo_pass'), hashValue(inputRef.current.value), {expires: 1});
          setLoggedIn(true)
        }
        
        if( hashValue(inputRef.current.value) != '' &&  hashValue(inputRef.current.value) != process.env.REACT_APP_PASS ) {
          setWrongPass(true);
        }
      }

      const checkKey = (e) => {
        if( e.key == "Enter") {
          checkPassoword();
        }
      }

  const dataArray = useLoaderData();
    if( dataArray.hasOwnProperty('content_data')) {
        const textConfig = dataArray.textConfig;
        //const staticData = dataArray.static_data;
        const content = dataArray.content_data;

        const breakpoints = [1920, 768];
        const photos = content.images.map((photo) => {
            const width = breakpoints[0];
            // const height = (photo.height / photo.width) * width;
            const height = (600 / 800) * width;
            
            return {
                src: photo.wide,
                width,
                height,
                srcSet: breakpoints.map((breakpoint) => {
                    const height = Math.round((photo.height / photo.width) * breakpoint);
                    return {
                        src: photo.wide,
                        width: breakpoint,
                        height,
                    };
                }),
            };
        });

        const handleClick = ( index ) => {
            setIndex(index);
        }

        if( loggedIn ) {
        return (
            <section id="photogallery" className="section-photogallery">
                <div className="container"> 
                        <ResponsiveMasonry columnsCountBreakPoints={{320: 2, 620: 3, 820: 4}}>
                            <Masonry gutter="10px">
                                { content.images.map( (item, index ) => {
                                    return <img loading="lazy" key={ item.wide } src={item.thumbnail} onClick={() => handleClick(index)} />
                                }) }
                            </Masonry>
                        </ResponsiveMasonry>  

                        <Lightbox
                            slides={photos}
                            open={index >= 0}
                            index={index}
                            close={() => setIndex(-1)}
                            plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                        />

                    {/* <div className="photogallery-wrapper">
                        <h2>{ content.title}</h2>
                        <p dangerouslySetInnerHTML={{__html:content.text }}></p>

                        <ul className="photogallery-list">
                            { content.hasOwnProperty('images') && content.images.map( (item, index ) => {
                                item.delay = (index%3)*300;
                                return <li data-aos-duration="1200" data-aos-delay={ item.delay } data-aos="fade-in">
                                    <div className="photo-image">

                                        <img src={ item.thumbnail} alt="Silueta" />
                                        <p>{item.wide}</p>
                                    </div>
                                    <div className="photo-info">
                                        { item?.title }
                                    </div>
                                </li>
                            }) }
                        </ul>

                    </div> */}
                </div>
            </section>
        )
        }
        else {
            return(
                <section id="program" className="section-program section-videos">
                    <div className="container">
                        <h1>{ content.title }</h1>
                        <h2>{ textConfig.enterPassword }</h2>
                        <div className="form-input-text">
                        <input onKeyUp={ checkKey } ref={inputRef} type="password" />
                        { wrongPass && <div className="input-error">{ textConfig.wrongPassword }</div>}
                        </div>
                        <div className="buttons">
                            <div onClick={ checkPassoword } className="btn-hero"><span>{ textConfig.send }</span></div>
                            {/* <div onClick={() => navigate(-1)} className="btn-hero"><span>{ textConfig.back }</span></div> */}
                        </div>
                    </div>
                </section>
            )
        }
        } else {
        return(
            <div className="App">
                <ErrorPage ManualError={dataArray}/>
            </div>
        );
    }
}