import React, { useState } from "react";
import { Link } from "react-router-dom";
import {getQuestionCountFromCookie, hashValue} from "../../api";
import Cookies from "js-cookie";
import { useEffect } from "react";

export default function Step_4( {changeStep, content}) {
  let step_order = 4;
  let step_content = ( content.questionpage.field_steps.length > step_order-1 ? content.questionpage.field_steps[step_order] : {} );
  const question_id = content.question.uid;

  let form_html = [];
  content.question.answers.forEach(function(answer, index){
    form_html.push(<div className="form-checkbox" key={index}>
      <input type="checkbox" id={"answer_"+index} name="answers" value={answer} />
      <label htmlFor={"answer_"+index}>{(index+1) + ". " + answer}</label>
    </div>    );
  })

  const [ popupText, setPopupText] = useState( false );
  const [ submitButton, setSubmitButton ] = useState( false);


  const togglePopup = () => {
    let popup_texts = {};
    let questionCount = getQuestionCountFromCookie();
    if (questionCount === 9){
        popup_texts.title = step_content.field_popup_title_4;
        popup_texts.number = '<div class="scanned-result"><span>'+questionCount+'</span></div>';
        popup_texts.text = step_content.field_popup_text_4.replace('%numbers',popup_texts.number);
    } else if ( questionCount > 6){
        popup_texts.title = step_content.field_popup_title_3;
        popup_texts.number = '<div class="scanned-result"><span>'+questionCount+'</span></div>';
        popup_texts.text = step_content.field_popup_text_3.replace('%numbers',popup_texts.number);
    } else if( questionCount === 6 ){
        popup_texts.title = step_content.field_popup_title_2;
        popup_texts.number = '<div class="scanned-result"><span>'+questionCount+'</span><span>/</span><span>6</span></div>';
        popup_texts.text = step_content.field_popup_text_2.replace('%numbers',popup_texts.number);
    } else {
        popup_texts.title = step_content.field_popup_title_1;
        popup_texts.number = '<div class="scanned-result"><span>'+questionCount+'</span><span>/</span><span>6</span></div>';
        popup_texts.text = step_content.field_popup_text_1.replace('%numbers',popup_texts.number);
    }
      setPopupText(popup_texts);
  }

  if( popupText === false) {
      let user_data = Cookies.get(hashValue('wppexpo_data'));
      let user_data_json = JSON.parse(user_data);
      if ( user_data_json.hasOwnProperty(hashValue('questions'))) {
          if (user_data_json[hashValue('questions')].indexOf(question_id) > -1) {
              togglePopup();
          }
      }
  }

  useEffect( () => {
    let questionCount = getQuestionCountFromCookie();
    if( questionCount == 6) {
      togglePopup();
    }
  }, [])

  const handleChange = () => {
    let checkedCheckboxes = document.querySelectorAll('.form-checkbox input:checked').length;
    checkedCheckboxes !== 3 ? setSubmitButton( false) : setSubmitButton(true);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let answers_checked = [];
    
    for(let i = 0; i < event.target.elements['answers'].length; i++)
    {
        if(event.target.elements['answers'][i].checked)
            answers_checked.push(event.target.elements['answers'][i].value);
    }
    //save into cookie
    let user_data = Cookies.get(hashValue('wppexpo_data'));
    let user_data_json = JSON.parse(user_data);
    if(! user_data_json.hasOwnProperty(hashValue('questions'))){
        user_data_json[hashValue('questions')] = [];
    }
    if(user_data_json[hashValue('questions')].indexOf(question_id) === -1) {
        user_data_json[hashValue('questions')].push(question_id);
    }
    Cookies.set(hashValue('wppexpo_data'), JSON.stringify(user_data_json), { expires: Number(process.env.REACT_APP_COOKIE_EXPIRE) });

    //send to exponea
    let exponeaTrackingName = "questionary";
    let currentStandTitle = content.question.title;
    let currentStand = {
      "stand": currentStandTitle,
      "answers": answers_checked
    };
    window.globalExpo.track(exponeaTrackingName, currentStand);
    togglePopup();
  };


  return (
          <div className={`containerapp stand ${ popupText !== false ? "popup-active" : ""}`}>
            <div className="containerapp-inner">
              <div className={"intro stands-bg--"+content.question.background}>
                
                <h1 className="h1">
                  {content.question.title}
                </h1>
                  <div dangerouslySetInnerHTML={{__html: content.question.question}}></div>
              </div>

                <div>
                        <form className="select" onSubmit={handleSubmit}>
                            <fieldset onChange={ handleChange } className="stands-bg--brand">
                                    {form_html.map((element)=> {
                                        return element;
                                    })
                                  }

                            </fieldset>
                            <div className="buttons bg-none">
                              {(step_content.next_button !== '' &&
                                  <button disabled={!submitButton} type="submit" className="primary">{step_content.next_button}</button>
                              )}
                              {(step_content.back_button !== '' &&
                                  <Link to="/" className="button secondary">{step_content.back_button}</Link>
                              )}
                            </div>
                    </form>
                    <div className="hint stands-bg--brand" dangerouslySetInnerHTML={{__html: step_content.field_instructions}}></div>
                    </div>

            </div>

            { typeof popupText == 'object' &&
              <div className="popup">
                <div className="popup-inner">
                  <p><strong>{popupText.title}</strong></p>
                    <div className="scanned" dangerouslySetInnerHTML={{__html: popupText.text}}></div>
                  <button onClick={ changeStep } className="secondary">OK</button>
                </div>
              </div> 
              }

          </div>
      )
}