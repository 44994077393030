import React from "react";

import CD from 'react-countdown';

export default function Countdown( {staticData} ) {

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span></span>;
    } else {
      // Render a countdown
      return(
        <div  className="countdown-wrapper">
          <div className="countdown-unit">
            <span className="countdown-value" id="countdown-days">{days}</span>
            <span className="countdown-label">{ staticData.time.day }</span>
          </div>
          <div className="countdown-unit">
            <span className="countdown-value" id="countdown-hours">{hours}</span>
            <span className="countdown-label">{ staticData.time.hour }</span>
          </div>
          <div className="countdown-unit">
            <span className="countdown-value" id="countdown-minutes">{minutes}</span>
            <span className="countdown-label">{ staticData.time.minute }</span>
          </div>
          <div className="countdown-unit">
            <span className="countdown-value" id="countdown-seconds">{seconds}</span>
            <span className="countdown-label">{ staticData.time.second }</span>
          </div>
        </div>
      );
    }
  };

  return(
    <section className="section-countdown">
      <div data-aos="fade-in" data-aos-delay="1600" data-aos-duration="2200" className="container">
        <h2>{ staticData.label }</h2>
        <CD renderer={ renderer } zeroPadDays={2} date={ staticData.timestamp + 10000 } />
        <h2>{ staticData.labelBottom }</h2>
      </div>
    </section>
  )
}