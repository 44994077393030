import React from "react";


export default function MapItem( { staticData } ) {
    const maps = staticData.files.map( (file, index) => {
        return <div>
            <h3><a href={file.url} target="_blank" >{staticData.label}</a></h3>
            <embed
            src={file.url+"#toolbar=1&navpanes=0&scrollbar=0"}
            type="application/pdf"
            key={index}
            frameBorder="0"
            scrolling="auto"
            height="800px"
            width="100%"
            ></embed>
          </div>
    })

  return(
    <div>
      { maps }
    </div>
  )
}

