import React, { useEffect, useState } from "react";
import AOS from 'aos';

import { useLoaderData } from "react-router-dom";
import textConfig from "../textConfig";
import fetchApi, {hashValue, setFileFromUUID} from "../api";
import ErrorPage from "./ErrorPage";
import Cookies from 'js-cookie';

import loadExponea from '../components/app/exponea.js'

// static steps
import Step_1 from "../components/app/Step_1.js";
import Step_2 from "../components/app/Step_2.js";
import Step_3 from "../components/app/Step_3.js";
import Step_4 from "../components/app/Step_4.js";
import Step_5 from "../components/app/Step_5.js";



function prepareData(content){
    let returned_data = content.currentQuestion;
    return returned_data;
}

function prepareDataPage(content_page){
    if( content_page.length > 0) {
         let returned_data_page = content_page[0];
         if( returned_data_page.hasOwnProperty('field_steps') ){
             returned_data_page.field_steps.forEach(function (paragraph, index){
                if( paragraph.hasOwnProperty('field_image') && paragraph.field_image !== ''){
                    returned_data_page.field_steps[index].field_image = setFileFromUUID(paragraph.field_image, content_page.files, ['url'], 'wide');
                }
             });
         }
         return returned_data_page;
    }
    return {};
}

export async function loader ({params}) {
    let lang = 'cs';
    let questionID = '';
    if( params.hasOwnProperty('lang') ){
        if( params.lang === 'en' ){
            lang = params.lang;
        }
    }
    if( params.hasOwnProperty('questionID') ){
        if( params.questionID ){
            questionID = params.questionID;
        }
    }
    let textConfigLocal = textConfig[lang];
    try {
        let return_content = {};
        let questionpage = await fetchApi({ type: 'questionpage' , lang: lang });
        return_content.questionpage = prepareDataPage(questionpage);
        if( questionID !== '' ) {
            let question = await fetchApi({type: 'question', lang: lang, questionID: questionID});
            return_content.question = prepareData(question, textConfigLocal);
        }
        return_content.textConfig = textConfigLocal;
        return { content_data: return_content};
    } catch (e){
        return e;
    }
}

export default function QuestionPage( {data} ) {

    useEffect(() => {
        AOS.init();
    }, [])

    const [loaded, setLoaded] = useState(false);
   
    useEffect(() => {
        loadExponea(() => {
            setLoaded(true);
            // var globalExpo = exponea;
        });
    });

    const styles = "header, footer, .section-partners, .section-contact{ display: none;}";
    const [step, setStep] = useState(1);

    //set Step after registration
    let user_data = Cookies.get(hashValue('wppexpo_data'));
    if( user_data) {
        let user_data_json = JSON.parse(user_data);
        if (user_data_json.hasOwnProperty(hashValue('data_saved')) && user_data_json[hashValue('data_saved')] === true) {
            if( step === 1 || step === 3 ){
                setStep(4);
            }
        }
    }
    
    const changeStep = () => {
        setStep( (prev) => prev + 1);
    }

    const backStep = () => {
        setStep( (prev) => prev - 1);
    }

    const dataArray = useLoaderData();
    if( dataArray.hasOwnProperty('content_data')) {
        const content = dataArray.content_data;
        return (
            <>
            <style>
                { styles }
            </style>
            { step === 1 && <Step_1 content={content} changeStep={ changeStep }></Step_1> }
            { step === 2 && <Step_2 content={content} changeStep={ changeStep } backStep={ backStep }></Step_2> }
            { step === 3 && <Step_3 content={content} changeStep={ changeStep } backStep={ backStep }></Step_3> }
            { step === 4 && <Step_4 content={content} changeStep={ changeStep }></Step_4> }
            { step === 5 && <Step_5 content={content}></Step_5> }
            </>
        )
    } else {
        return(
            <div className="App">
                <ErrorPage ManualError={dataArray}/>
            </div>
        );
    }
}