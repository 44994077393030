import React, { useEffect } from "react";
import AOS from 'aos';

import { useLoaderData } from "react-router-dom";

import staticDataJS from '../staticData.js'
import Speakers from '../components/Speakers.js'
import staticDataJSEN from "../staticDataEN";
import textConfig from "../textConfig";
import fetchApi, {setFileFromUUID} from "../api";
import ErrorPage from "./ErrorPage";
import Stand from "../components/Stand.js";

function prepareData(content,textConfigLocal){
    let returned_data = {}
    returned_data = content.currentStand;

    let pdfs = [];
    returned_data.files.forEach(function(file){
        let text = file.meta?.description;
        if( text == ''){
            text = textConfigLocal.download;
        }
        let pdf = setFileFromUUID(file.id, content.files, ['url'])?.url;
        pdfs.push({url:pdf,text: text });
    });

    returned_data.pdfs = pdfs;

    return returned_data;
}

export async function loader ({params}) {
    let staticData = await staticDataJS;
    let lang = 'cs';
    let worldID = '';
    if( params.hasOwnProperty('lang') ){
        if( params.lang == 'en' ){
            lang = params.lang;
            staticData = await staticDataJSEN;
        }
    }
    if( params.hasOwnProperty('worldID') ){
        if( params.worldID ){
            worldID = params.worldID;
        }
    }
    let textConfigLocal = textConfig[lang];
    try {
        let content_config = await fetchApi({ type: 'config' , lang: lang });
        let content = await fetchApi({ type: 'stand' , lang: lang, worldID: worldID });
        content = prepareData(content,textConfigLocal);
        content.textConfig = textConfigLocal
        content.config = content_config.config;
        return { content_data: content};
    } catch (e){
        return e;
    }
}

export default function World( {data} ) {

    useEffect(() => {
        AOS.init();
    }, [])

    const dataArray = useLoaderData();
    if( dataArray.hasOwnProperty('content_data')) {
        const content = dataArray.content_data;
        return (
            <Stand staticData={content}/>
        )
    } else {
        return(
            <div className="App">
                <ErrorPage ManualError={dataArray}/>
            </div>
        );
    }
}