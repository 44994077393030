import React from "react";

import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css";

export default function About( { staticData } ) {
  return(
    <section id="about" className="section-about">
      <div data-aos-delay="500" data-aos-duration="2000" data-aos="fade-right" className="container aos-init">
        <div className="about-side">
          { staticData.side }
        </div>
        <div className="about-text">
          <h2>{ staticData.title }</h2>
          <div data-aos-duration="1200" data-aos="fade-right" className="aos-init" dangerouslySetInnerHTML={{__html: staticData.text}}></div>
        </div>
        <div className="about-video">
          <div dangerouslySetInnerHTML={{__html: staticData.video_embed}} />
          {/* <div className="field--name-field-media-oembed-video about-video-inner">
              <iframe src="https://www.youtube.com/embed/uiJsw5NV1JU" title="WPP EXPO - Worlds of Marketing | Prague | June 2023" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div> */}
        </div>
      </div>
    </section>
  )
}