import React from "react";

export default function Step_1( { changeStep, content } ) {
    let step_order = 1;
    let step_content = ( content.questionpage.field_steps.length > step_order-1 ? content.questionpage.field_steps[step_order] : {} );

        return (
            <div className="containerapp">
            <div className="containerapp-inner">
            <div className="logo">
              <img src="/assets/images/app-logo.png" alt="WPP Logo" className="logo" />
            </div>
            <h1 className="h1">{step_content.field_title}</h1>
                { step_content.field_image?.url &&
                    <img src={step_content.field_image.url} className="steps" alt="Steps" />
                }
                <div dangerouslySetInnerHTML={{__html: step_content.body}}></div>
                {(step_content.next_button !== '' &&
            <button onClick={ changeStep } className="primary">
                {step_content.next_button}
            </button>
                    )}
          </div>
        </div>
        )
}