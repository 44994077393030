import {Component} from "react";

class ErrorBoundary extends Component{
    constructor(props) {
        super(props);
        this.state = { hasError: false};
    }

    static getDerivedStateFromError(error){
        return {
            hasError: true,
            error
        };
    }

    componentDidCatch(error, errorInfo) {
    }

    render() {
        const {hasError, error} = this.state;

        if(hasError){
            return(
                <div className="error">
                    <h3>Something went wrong</h3>
                    <p>{error.message}</p>

                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;