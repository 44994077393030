import React from "react";
import ProgramItem from './ProgramItem';

export default function ProgramList( { staticData, textConfig } ) {

  const programItems = staticData.items.map( (item, index) => {
    return <ProgramItem key={ index } staticData={ item } textConfig={textConfig} />
  })

  let class_name = "program-list category-"+staticData.type_id;
  return(
    <div className={class_name}>
    <div className="program-title" id={staticData.type_id}>
      { staticData.type != '<none>' && staticData.type }
    </div>
      <ul className="program-items">
        { programItems }
      </ul>
    </div>
  )
}

