const data = {
    cs: {
        lang: "cs",
        recordings: {
            title: "Záznamy",
            enterPassword: "Zadejte heslo",
            wrongPassword: "Nesprávné heslo",
            send: "Odeslat",
            back: "Zpět"
        },
        record: "Záznam přednášky",
        time: {
            day: "dní",
            hour: "hodin",
            minute: "minut",
            second: "sekund",
            format: "d. M Y"
        },
        months: {
            1: "ledna",
            2 : "února",
            3: "března",
            4: "dubna",
            5: "května",
            6: "června",
            7: "července",
            8: "srpna",
            9: "září",
            10: "října",
            11: "listopadu",
            12: "prosince"
        },
        download: "Stáhnout one-pager",
        more: "Více"
    },
    en: {
        lang: "en",
        recordings: {
            title: "Recordings",
            enterPassword: "Enter password",
            wrongPassword: "Wrong password",
            send: "Send",
            back: "Back"
        },
        record: "Record",
        time: {
            day: "days",
            hour: "hours",
            minute: "minutes",
            second: "seconds",
            format: "M d Y"
        },
        months: {
            1: "January",
            2: "February",
            3: "March",
            4: "April",
            5: "May",
            6: "June",
            7: "July",
            8: "August",
            9: "September",
            10: "October",
            11: "November",
            12: "December"
        },
        download: "Download one-pager",
        more: "More"
    }
};

export default data;