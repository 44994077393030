import React from "react";
import ProgramVideoItem from "./ProgramVideoItem";
export default function ProgramVideoList( { staticData } ) {

  const programItems = staticData.items.map( (item, index) => {
      return <ProgramVideoItem key={ index } staticData={ item } />
  })

  let class_name = "program-list category-"+staticData.type_id;
  return(
    <div className={class_name}>
    <div className="program-title" id={staticData.type_id}>
      { staticData.type != '<none>' && staticData.type }
    </div>
      <ul className="program-items program-items-videos grid-list">
        { programItems }
      </ul>
    </div>
  )
}

