import React from "react";
import {getQuestionCountFromCookie} from "../../api";



export default function Step_5({content}) {
    let step_order = 5;
    let step_content = ( content.questionpage.field_steps.length > step_order-1 ? content.questionpage.field_steps[step_order] : {} );
    let question_count = getQuestionCountFromCookie();
        return (
          <div className="containerapp">
                <div className="containerapp-inner">
                    <div className="logo">
                        <img src="/assets/images/app-logo.png" alt="WPP Logo" className="logo" />
                    </div>
                    <h1 className="h1">{step_content.field_title}</h1>
                    { (question_count > 5 ?
                        <div dangerouslySetInnerHTML={{__html: step_content.success}}></div>
                        :
                        <div dangerouslySetInnerHTML={{__html: step_content.fail}}></div>
                        )}

                </div>
            </div>
        )
}