import React, { useState } from "react";

export default function ProgramVideoItem( { staticData } ) {

  return(
    
    <li data-aos-duration="1200" data-aos="fade-right" className="aos-init">
      {/*<div className="program-col">
      <div className="program-time">{ staticData.time }</div>
      { staticData.place ? <div className="program-place">{ staticData.place }</div> : false }
    </div>*/}
    <div className="program-col">
      <h3 className="program-label">
        { staticData.label }
      </h3>
    </div>
    <div className="program-col program-col-info">
        <div className="program-text" dangerouslySetInnerHTML={{__html: staticData.text}}>
        </div>
      { staticData.link?.url ? <a href={staticData.link.url}>{ staticData.link.text }</a> : false }
    </div>
    
      <div className="program-detail active">
        <div className="program-detail-inner" dangerouslySetInnerHTML={{__html: staticData.detail}} />
      </div> 

  </li>
  )
}