import { useEffect } from "react"

export default function ScrollTopButton() {


  const scrollToTop = () => {
    window.scrollTo({ top:0, behavior: 'smooth'});
  }

  return(
    <div onClick={ scrollToTop } className="scroll-top-button">
      
    </div>
  )
}

