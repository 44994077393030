import React, { useEffect } from "react";
import AOS from 'aos';

import fetchApi, {setFileFromUUID, formatDate, setRowFromUUID, setProgramCategoryFromUUID} from '../api.js';

import {useLoaderData} from "react-router-dom";
import Map from '../components/Map.js'

import staticDataJS from '../staticData.js'
import staticDataJSEN from '../staticDataEN.js'
import textConfig from '../textConfig.js'

function prepareData(content,textConfigLocal={}){
    let returned_data = {}
    let paragraphs = content[0].field_paragraphs;
    let pageInfo = {}
    pageInfo.title = content[0].title;
    pageInfo.body = content[0].body;
    paragraphs.forEach(function (item){
        switch (item.field_display_style){
            case 'map':
                let maps = [];
                item.maps.forEach(function(map_row) {
                    let maps_row = setRowFromUUID(map_row.id, content.maps);
                    let pdfs = [];

                    
                    maps_row.files.forEach(function(file){
                        let pdf = setFileFromUUID(file.id, content.files, ['url'])?.url;
                        pdfs.push({url:pdf,text: textConfigLocal.download});
                    });
                    
                    maps.push({
                        label: maps_row.title,
                        text: maps_row.body,
                        class:maps_row.class,
                        url: maps_row.field_url,
                        files: pdfs,
                        more: textConfigLocal.more
                    });
                });
                returned_data.map = {
                    label: item.field_title,
                    text: item.body,
                    maps: maps
                };
                break;
        }
    });
    returned_data.map.pageInfo = pageInfo;
    return returned_data;
}

export async function loader ({params}) {
    let staticData = await staticDataJS;
    let lang = 'cs';
    let textConfigLocal = textConfig[lang];
    if( params.hasOwnProperty('lang') ){
        if( params.lang == 'en' ){
            lang = params.lang;
            textConfigLocal = textConfig[lang];
            staticData = await staticDataJSEN;
        }
    }
    try {
        let content = await fetchApi({type: 'mappage', lang: lang});
        content = prepareData(content, textConfigLocal);
        return {static_data: staticData.pages.mappage, content_data: content};
    } catch (e){
        return {static_data: staticData.pages.mappage, content_data: {}};
    }
}



export default function MapPage({data}) {
    const dataArray = useLoaderData();
    //const staticData = dataArray.static_data;
    const content = dataArray.content_data;
  useEffect(() => {
    AOS.init();
  }, [])

  let ImportedComponents = {
    Map
    }

    let page = [];
    for (const [key, value] of Object.entries(content)) {
        let element = key.charAt(0).toUpperCase() + key.slice(1);
        page.push({ element: element, value: value});
    }

    let components = page.map((value) => {
        if( ImportedComponents[value.element] ) {
            let el = React.createElement(ImportedComponents[value.element], {
                staticData: value.value,
                key: value.element,
            }, null);
            return el;
        }
    })

  return(
        <>
            { components }
        </>
  )
}