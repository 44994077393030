import React from "react";
import StandItem from "./StandItem";

export default function Stands( {staticData} ) {

  const StandsList = staticData.stands.map( item => {
    return <StandItem key={ item.label } staticData={ item } />
  })

  return(
    <section id="stands" className="section-stands">
    <div className="container">
      <div className="stands-wrapper">
        <h2>{ staticData.label }</h2>
        <div data-aos-duration="1200" data-aos="fade-right" className="aos-init" dangerouslySetInnerHTML={{__html: staticData.text}}>
        </div>
        <ul className="stands-list">

          { StandsList }

        </ul>
      </div>
      {/* <div className="stands-more">
        <div className="btn-hero"><a href="/">MAPA STANOVIŠŤ</a></div>
      </div> */}
    </div>
  </section>
  )
}