export default function SpeakerItem( {staticData, itemIndex } ) {
  return(
  <li data-aos-duration="1200" data-aos-delay={ staticData.delay } data-aos="fade-in" className={`${staticData.class ? staticData.class : ''}`}>
    <div className="speaker-image">
      
      <img src={ staticData.image.url} alt="Silueta" />
    </div>
    <div className="speaker-info">
      <h3>{ staticData.name }</h3>
      <p dangerouslySetInnerHTML={{__html:staticData.text }}>
      </p>
    </div>
  </li>
  )
}

