import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {hashValue} from "../../api";
import { Link } from "react-router-dom";

export default function Step_3({changeStep, content, backStep }) {
    let step_order = 3;
    let step_content = ( content.questionpage.field_steps.length > step_order-1 ? content.questionpage.field_steps[step_order] : {} );
    const [checkbox, changeCheckbox] = useState(true);

    let form_html = [];
    let form = {};

    try {
        form = JSON.parse(step_content.field_json);
        form?.inputs.forEach(function (input_element) {
            form_html.push(<div key={input_element.id} className="form-input"><label
                htmlFor={input_element.id}>{input_element.label}</label><input type={input_element.type}
                                                                               placeholder={input_element.placeholder}
                                                                               name={input_element.name}
                                                                               id={input_element.id}
                                                                               required={input_element.required}/>
            </div>);
        })
    } catch (e) {
    }

    const handleCheckbox = (event) => {
        event.stopPropagation();
        changeCheckbox( prev => prev = !prev );
    }



    const handleSubmit = (event) => {
        event.preventDefault();
        let values = {};
        if( form ) {
            form?.inputs.forEach(function (input_element) {
                values[input_element.name] = event.target.elements[input_element.name].value;
            });
            //send to exponea
                let exponeaTrackingName = "questionary_user";
                window.globalExpo.track(exponeaTrackingName, values);
            //save into cookie
            let user_data = {};
            user_data[hashValue('data_saved')] = true;

            Cookies.set(hashValue('wppexpo_data'), JSON.stringify(user_data), {expires: Number(process.env.REACT_APP_COOKIE_EXPIRE)});
            changeStep();
        }
    };
        return (
            <div className="containerapp">
            <div className="containerapp-inner">
            <div className="logo">
              <img src="/assets/images/app-logo.png" alt="WPP Logo" className="logo" />
            </div>
                <h1 className="h1">{step_content.field_title}</h1>
                { step_content.field_image?.url &&
                    <img src={step_content.field_image.url} className="steps" alt="Steps" />
                }

                <div dangerouslySetInnerHTML={{__html: step_content.body}}></div>

            <form onSubmit={handleSubmit} id="wppexpo_userdataform">
                {form_html.map((element)=> {
                    return element;
                    })
                }
                {(step_content.field_gdpr !== '' &&
                    <div  className="checkbox-confirm">
                        <input  type="checkbox" id="checkbox_gdpr" name="checkbox_gdpr" value="checkbox_gdpr" required="required" />
                        <label onClick={handleCheckbox} htmlFor="checkbox_gdpr"><span>{step_content.back_button}</span></label><Link to={step_content.field_gdpr} target="_blank"> terms of participation</Link>
                    </div>
                )}
                <div className="buttons bg-none">
                    {(step_content.next_button !== '' &&
                        <button disabled={ checkbox } type="submit" className="primary">{step_content.next_button}</button>
                    )}
                </div>
            </form>


          </div>
          </div>
        )
}