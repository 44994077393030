import Cookies from "js-cookie";
import { encode } from 'js-base64';

const DRUPAL_API = process.env.REACT_APP_DRUPAL_API;

export default async function fetchApi(params){

    const requestUrl = DRUPAL_API+setAPIPath(params);
    try {
        const response = await fetch(requestUrl, {
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/vnd.api+json'
            }});
        let data_tmp = await response.json();
        return prepareResponse(params,data_tmp);
    } catch (e) {
        console.log(e);
        throw new Error('bohužel se nepodařilo načíst data ');
    }
}

function setAPIPath(params) {

    const current_year = new Date().getFullYear();
    let path = '/'+params.lang+'/jsonapi';
    switch (params.type) {
        case 'homepage':
            path += '/node/page?filter[page_type][path]=field_page_type&filter[page_type][value]=default&filter[status][value]=1&filter[year][path]=field_year&filter[year][value]='+current_year+'&include=field_paragraphs,field_paragraphs.field_image,field_paragraphs.field_video,field_paragraphs.field_stands,field_paragraphs.field_stands.field_files,field_paragraphs.field_program,field_paragraphs.field_program.field_categories,field_paragraphs.field_speakers,field_paragraphs.field_speakers.field_image';
            break;
        case 'mappage':
            path += '/node/page?filter[page_type][path]=field_page_type&filter[page_type][value]=map&filter[status][value]=1&filter[year][path]=field_year&filter[year][value]='+current_year+'&include=field_paragraphs,field_paragraphs.field_image,field_paragraphs.field_maps,field_paragraphs.field_maps.field_files'
            break;
        case 'questionpage':
            path += '/node/page?filter[page_type][path]=field_page_type&filter[page_type][value]=questions&filter[status][value]=1&filter[year][path]=field_year&filter[year][value]='+current_year+'&include=field_paragraphs,field_paragraphs.field_image';
            break;
        case 'videopage':
            path += '/node/page?filter[page_type][path]=field_page_type&filter[page_type][value]=videos&filter[status][value]=1&filter[year][path]=field_year&filter[year][value]='+current_year+'';
            break;
        case 'programpage':
            path += '/node/page?filter[page_type][path]=field_page_type&filter[page_type][value]=default&filter[status][value]=1&filter[year][path]=field_year&filter[year][value]='+current_year+'&include=field_paragraphs,field_paragraphs.field_video,field_paragraphs.field_program,field_paragraphs.field_program.field_categories'
            break;
        case 'photos':
            path += '/node/page?filter[page_type][path]=field_page_type&filter[page_type][value]=photos&filter[status][value]=1&filter[year][path]=field_year&filter[year][value]='+current_year+'&include=field_paragraphs,field_paragraphs.field_photos';
            break;
        case 'speakers':
            path += '/node/nodelist?filter[status][value]=1&filter[year][path]=field_year&filter[year][value]='+current_year+'&filter[type][path]=field_type&filter[type][value]=speakers&include=field_entity,field_entity.field_image';
            break;
        case 'world': //deprecated
            path += '/node/nodelist?filter[status][value]=1&filter[year][path]=field_year&filter[year][value]='+current_year+'&filter[type][path]=field_type&filter[type][value]=stands&include=field_entity,field_entity.field_files';
            break;
        case 'question':
            path += '/node/question?filter[url][path]=field_slug&filter[url][value]='+params.questionID+'';
            break;
        case 'stand':
            path += '/node/stand?filter[status][value]=1&filter[url][path]=field_slug&filter[url][value]='+params.worldID+'&include=field_files';
            break;
        case 'live-stream':
            path += '/node/livestream?filter[status][value]=1&filter[url][path]=field_slug&filter[url][value]='+params.stage;
            break;
        case 'live-streams':
            path += '/node/livestream?filter[status][value]=1';
            break;
        case 'config':
            path += '/node/config?filter[status][value]=1&filter[year][path]=field_year&filter[year][value]='+current_year;
            break;
        case 'contact_block':
            path += '/block_content/contact_block';
            break;
        case 'partners_block':
            path += '/block_content/partners_block?include=field_logo_1,field_logo_2,field_logo_3,field_logo_4';
            break;
        case 'menu':
            switch(params.name){
                case 'footer':
                    path += '/menu_items/footer-wpp';
                    break;
                case 'footer_2':
                    path += '/menu_items/footer-wpp-2';
                    break;
                case 'header':
                    path += '/menu_items/header-menu';
                    break;
            }
            break;
    }
    return path;
}


function prepareResponse(params, response){
    const type = params.type;
    let response_object = [];
    switch(type) {
        case 'homepage':
            if (response.data.length === 0) {
                return response.data;
            }
            response.data.forEach(function (item, index) {
                response_object.push({
                    id: item.id,
                    type: 'homepage'
                });
                if (item.relationships.hasOwnProperty('field_paragraphs')) {
                    let paragraphs = item.relationships.field_paragraphs.data;
                    response_object[index].field_paragraphs = [];

                    paragraphs.forEach(function (paragraph_item) {
                        response_object[index].field_paragraphs.push({
                            paragraph_id: paragraph_item.id,
                            paragraph_type: paragraph_item.type
                        });
                    });
                }

                if (response.hasOwnProperty('included')) {
                    let included = response.included;
                    response_object.files = [];
                    response_object.stands = [];
                    response_object.speakers = [];
                    response_object.program_rows = [];
                    response_object.program_categories = [];

                    included.forEach(function (included_item) {
                        //all files into one object
                        switch (included_item.type) {
                            case 'file--file':
                                response_object.files[included_item.id] = {
                                    id: included_item.attributes.drupal_internal__fid,
                                    formats: included_item.attributes.image_style_uri,
                                    url: included_item.attributes.uri?.url
                                };
                                break;
                            case 'node--program_row':
                                response_object.program_rows[included_item.id] = {
                                    id: included_item.attributes.drupal_internal__nid,
                                    title: included_item.attributes.title,
                                    body: included_item.attributes.body?.processed,
                                    detail: included_item.attributes.field_html?.processed,
                                    time: included_item.attributes.field_time,
                                    place: included_item.attributes.field_place,
                                    category: included_item.relationships?.field_categories?.data?.id,
                                    link: included_item.attributes.field_link[0] //TODO
                                };
                                break;
                            case 'taxonomy_term--program_categories':
                                response_object.program_categories[included_item.id] = {
                                    id: included_item.attributes.drupal_internal__tid,
                                    name: included_item.attributes.name
                                };
                                break;
                            case 'node--stand':

                                response_object.stands[included_item.id] = {
                                    id: included_item.attributes.drupal_internal__nid,
                                    title: included_item.attributes.title,
                                    body: (included_item.attributes.body?.summary ? included_item.attributes.body?.summary : removeTags(included_item.attributes.body?.value) ),
                                    class: 'stands'+included_item.attributes?.field_class,
                                    url: included_item.attributes?.field_slug,
                                    show_link: !included_item.attributes?.field_hide_more,
                                    files: included_item.relationships?.field_files?.data
                                };
                                break;
                            case 'node--speaker':
                                response_object.speakers[included_item.id] = {
                                    id: included_item.attributes.drupal_internal__nid,
                                    name: included_item.attributes.title,
                                    text: included_item.attributes?.body?.processed,
                                    class: 'speaker'+included_item.attributes?.field_class,
                                    image: included_item.relationships?.field_image?.data?.id
                                };
                                
                                break;
                        }
                        
                        switch (included_item.attributes?.parent_field_name){
                            case 'field_paragraphs':                             
                                response_object[index].field_paragraphs.forEach(function (tmp_item,tmp_index,){
                                    if( included_item.id === tmp_item.paragraph_id ){
                                        
                                        let paragraph_object = {
                                            id: included_item.attributes?.drupal_internal__id,
                                            type: included_item.type,
                                            field_display_style: included_item.attributes?.field_display_style,
                                            field_title: included_item.attributes?.field_title?.value,
                                            field_image: included_item.relationships?.field_image?.data?.id,
                                        };
                                        switch (included_item.type) {
                                            case 'paragraph--header':
                                                paragraph_object.body = included_item.attributes.field_body?.value;
                                                paragraph_object.event_date = included_item.attributes.field_date;
                                                paragraph_object.field_image = included_item.relationships.field_image?.data?.id;
                                                paragraph_object.field_video = included_item.relationships.field_video?.data?.id;
                                                paragraph_object.field_subtitle = included_item.attributes?.field_subtitle?.value;
                                                paragraph_object.field_link =  included_item.attributes?.field_link;
                                            break;
                                            case 'paragraph--default_block':
                                                paragraph_object.event_date = included_item.attributes.field_date;
                                                paragraph_object.body = included_item.attributes.field_body?.processed;
                                                paragraph_object.field_subtitle = included_item.attributes.field_subtitle?.value;
                                                paragraph_object.video_embed = included_item.attributes?.field_video_embed?.processed;
                                                break;
                                            case 'paragraph--program':
                                                paragraph_object.program_rows = included_item.relationships.field_program?.data;
                                                paragraph_object.body = included_item.attributes.field_body?.processed;
                                                break;
                                            case 'paragraph--stands':
                                                paragraph_object.stands_rows = included_item.relationships.field_stands?.data;
                                                paragraph_object.body = included_item.attributes.field_body?.processed;
                                            break;
                                            case 'paragraph--speakers':
                                                paragraph_object.body = included_item.attributes.field_body?.processed;
                                                paragraph_object.title = included_item.attributes.field_title?.value;
                                                paragraph_object.field_image = included_item.relationships.field_image?.data?.id;
                                                paragraph_object.field_subtitle = included_item.attributes.field_subtitle?.value;
                                                paragraph_object.speakers_rows = included_item.relationships.field_speakers?.data;
                                                break;
                                        }
                                        response_object[index].field_paragraphs[tmp_index] = paragraph_object;
                                    }
                                });
                                break;
                        }
                    });
                }
            });
        break;

        case 'programpage':
            if (response.data.length === 0) {
                return response.data;
            }
            response.data.forEach(function (item, index) {
                response_object.push({
                    id: item.id,
                    type: 'programpage'
                });
                if (item.relationships.hasOwnProperty('field_paragraphs')) {
                    let paragraphs = item.relationships.field_paragraphs.data;
                    response_object[index].field_paragraphs = [];

                    paragraphs.forEach(function (paragraph_item) {
                        response_object[index].field_paragraphs.push({
                            paragraph_id: paragraph_item.id,
                            paragraph_type: paragraph_item.type
                        });
                    });
                }

                if (response.hasOwnProperty('included')) {
                    let included = response.included;
                    response_object.files = [];
                    response_object.program_rows = [];
                    response_object.program_categories = [];

                    included.forEach(function (included_item) {
                        //all files into one object
                        switch (included_item.type) {
                            case 'file--file':
                                response_object.files[included_item.id] = {
                                    id: included_item.attributes.drupal_internal__fid,
                                    formats: included_item.attributes.image_style_uri,
                                    url: included_item.attributes.uri?.url
                                };
                                break;
                            case 'node--program_row':
                                response_object.program_rows[included_item.id] = {
                                    id: included_item.attributes.drupal_internal__nid,
                                    title: included_item.attributes.title,
                                    body: included_item.attributes.body?.processed,
                                    detail: included_item.attributes.field_html?.processed,
                                    time: included_item.attributes.field_time,
                                    place: included_item.attributes.field_place,
                                    category: included_item.relationships?.field_categories?.data?.id,
                                    link: included_item.attributes.field_link[0] //TODO
                                };
                                break;
                            case 'taxonomy_term--program_categories':
                                response_object.program_categories[included_item.id] = {
                                    id: included_item.attributes.drupal_internal__tid,
                                    name: included_item.attributes.name
                                };
                                break;

                        }

                        switch (included_item.attributes?.parent_field_name){
                            case 'field_paragraphs':
                                response_object[index].field_paragraphs.forEach(function (tmp_item,tmp_index,){
                                    if( included_item.id === tmp_item.paragraph_id ){
                                        let paragraph_object = {
                                            id: included_item.attributes?.drupal_internal__id,
                                            type: included_item.type,
                                            field_display_style: included_item.attributes?.field_display_style,
                                            field_title: included_item.attributes?.field_title?.value,
                                            field_image: included_item.relationships?.field_image?.data?.id
                                        };
                                        switch (included_item.type) {
                                            case 'paragraph--program':
                                                paragraph_object.program_rows = included_item.relationships.field_program?.data;
                                                paragraph_object.body = included_item.attributes.field_body?.processed;
                                                break;

                                        }
                                        response_object[index].field_paragraphs[tmp_index] = paragraph_object;
                                    }
                                });
                                break;
                        }
                    });
                }
            });
            break;

        case 'videopage':
            if (response.data.length === 0) {
                return response.data;
            }
            response.data.forEach(function (item, index) {
                // if( item.id != '206d7d75-559e-45e3-9e9d-01048accde03') {
                //     return
                // }
                response_object.push({
                    id: item.id,
                    type: 'videopage',
                    title: item.attributes.title,
                    body: item.attributes.body?.processed
                });
            });
            break;

        case 'mappage':
            if (response.data.length === 0) {
                return response.data;
            }
            response.data.forEach(function (item, index) {
                // if( item.id != '206d7d75-559e-45e3-9e9d-01048accde03') {
                //     return
                // }
                response_object.push({
                    id: item.id,
                    type: 'mappage',
                    title: item.attributes.title,
                    body: item.attributes.body?.processed
                });

                if (item.relationships.hasOwnProperty('field_paragraphs')) {
                    let paragraphs = item.relationships.field_paragraphs.data;
                    response_object[index].field_paragraphs = [];

                    paragraphs.forEach(function (paragraph_item) {
                        response_object[index].field_paragraphs.push({
                            paragraph_id: paragraph_item.id,
                            paragraph_type: paragraph_item.type
                        });
                    });
                }

                if (response.hasOwnProperty('included')) {
                    let included = response.included;
                    response_object.files = [];
                    response_object.maps = [];
                    included.forEach(function (included_item) {
                        //all files into one object
                        switch (included_item.type) {
                            case 'file--file':
                                response_object.files[included_item.id] = {
                                    id: included_item.attributes.drupal_internal__fid,
                                    formats: included_item.attributes.image_style_uri,
                                    url: included_item.attributes.uri?.url
                                };
                            break;
                            case 'node--map':
                                response_object.maps[included_item.id] = {
                                    id: included_item.attributes.drupal_internal__nid,
                                    title: included_item.attributes.title,
                                    slug: included_item.attributes.field_title,
                                    files: included_item.relationships?.field_files?.data
                                };
                            break;
                        }
                        
                        switch (included_item.attributes?.parent_field_name){
                            case 'field_paragraphs':                             
                                response_object[index].field_paragraphs.forEach(function (tmp_item,tmp_index,){
                                    if( included_item.id === tmp_item.paragraph_id ){
                                        let paragraph_object = {
                                            id: included_item.attributes?.drupal_internal__id,
                                            type: included_item.type,
                                            field_display_style: included_item.attributes?.field_display_style,
                                            field_title: included_item.attributes?.field_title?.value,
                                        };
                                        switch (included_item.type) {
                                            case 'paragraph--map':
                                                paragraph_object.maps = included_item.relationships.field_maps?.data;
                                                paragraph_object.body = included_item.attributes.field_body?.processed;
                                            break;        
                                        }
                                        response_object[index].field_paragraphs[tmp_index] = paragraph_object;
                                    }
                                });
                                break;
                        }
                    });
                }
            });
        break;

        case 'questionpage':
            if (response.data.length === 0) {
                return response.data;
            }
            response.data.forEach(function (item, index) {
                response_object.push({
                    id: item.id,
                    type: 'questionpage'
                });
                if (item.relationships.hasOwnProperty('field_paragraphs')) {
                    let paragraphs = item.relationships.field_paragraphs.data;
                    response_object[index].field_paragraphs = [];

                    paragraphs.forEach(function (paragraph_item) {
                        response_object[index].field_paragraphs.push({
                            paragraph_id: paragraph_item.id,
                            paragraph_type: paragraph_item.type
                        });
                    });
                }

                if (response.hasOwnProperty('included')) {
                    let included = response.included;
                    response_object.files = [];
                    //response_object.maps = [];
                    response_object[index].field_steps = [];
                    let step_counter = 0;

                    included.forEach(function (included_item) {
                        //all files into one object
                        switch (included_item.type) {
                            case 'file--file':
                                response_object.files[included_item.id] = {
                                    id: included_item.attributes.drupal_internal__fid,
                                    formats: included_item.attributes.image_style_uri,
                                    url: included_item.attributes.uri?.url
                                };
                                break;
                        }

                        switch (included_item.attributes?.parent_field_name){
                            case 'field_paragraphs':

                                response_object[index].field_paragraphs.forEach(function (tmp_item,tmp_index,){
                                    if( included_item.id === tmp_item.paragraph_id ){
                                        let paragraph_object = {
                                            id: included_item.attributes?.drupal_internal__id,
                                            type: included_item.type,
                                            field_display_style: included_item.attributes?.field_display_style,
                                        };
                                        switch (included_item.type) {
                                            case 'paragraph--step':
                                                paragraph_object.field_title = included_item.attributes?.field_title_simple;
                                                paragraph_object.body = included_item.attributes.field_body?.processed;
                                                paragraph_object.next_button = included_item.attributes.field_next_button;
                                                paragraph_object.back_button = included_item.attributes.field_back_button;
                                                paragraph_object.field_image = included_item.relationships?.field_image?.data?.id;
                                                paragraph_object.field_json = included_item.attributes.field_json;
                                                paragraph_object.field_gdpr = included_item.attributes.field_gdpr_url;
                                                if( paragraph_object.field_display_style === 'step') {
                                                    step_counter++;
                                                    response_object[index].field_steps[step_counter] = paragraph_object;
                                                }
                                                break;
                                            case 'paragraph--step_question':
                                                paragraph_object.field_title = included_item.attributes?.field_title_simple;
                                                paragraph_object.body = included_item.attributes.field_body?.processed;
                                                paragraph_object.next_button = included_item.attributes.field_next_button;
                                                paragraph_object.back_button = included_item.attributes.field_back_button;
                                                paragraph_object.field_popup_text_1 = included_item.attributes.field_popup_text_1;
                                                paragraph_object.field_popup_text_2 = included_item.attributes.field_popup_text_2;
                                                paragraph_object.field_popup_text_3 = included_item.attributes.field_popup_text_3;
                                                paragraph_object.field_popup_text_4 = included_item.attributes.field_popup_text_4;
                                                paragraph_object.field_popup_title_1 = included_item.attributes.field_popup_title_1;
                                                paragraph_object.field_popup_title_2 = included_item.attributes.field_popup_title_2;
                                                paragraph_object.field_popup_title_3 = included_item.attributes.field_popup_title_3;
                                                paragraph_object.field_popup_title_4 = included_item.attributes.field_popup_title_4;
                                                paragraph_object.field_instructions = included_item.attributes.field_subtitle?.processed;
                                                if( paragraph_object.field_display_style === 'step') {
                                                    step_counter++;
                                                    response_object[index].field_steps[step_counter] = paragraph_object;
                                                }
                                                break;
                                            case 'paragraph--step_html':
                                                paragraph_object.field_title = included_item.attributes?.field_title_simple;
                                                paragraph_object.fail = included_item.attributes.field_body?.processed;
                                                paragraph_object.success = included_item.attributes.field_body_2?.processed;
                                                if( paragraph_object.field_display_style === 'step') {
                                                    step_counter++;
                                                    response_object[index].field_steps[step_counter] = paragraph_object;
                                                }
                                                break;
                                        }
                                        response_object[index].field_paragraphs[tmp_index] = paragraph_object;
                                    }
                                });

                                break;
                        }
                    });
                }
            });
            break;

        case 'photos':
            if (response.data.length === 0) {
                return response.data;
            }
            response.data.forEach(function (item, index) {

                response_object.push({
                    id: item.id,
                    type: 'photospage',
                    title: item.attributes?.title,
                    text: item.attributes?.body?.processed
                });

                if (item.relationships.hasOwnProperty('field_paragraphs')) {
                    let paragraphs = item.relationships.field_paragraphs.data;
                    response_object[index].field_paragraphs = [];

                    paragraphs.forEach(function (paragraph_item) {
                        response_object[index].field_paragraphs.push({
                            paragraph_id: paragraph_item.id,
                            paragraph_type: paragraph_item.type
                        });
                    });
                }
            });

            if (response.hasOwnProperty('included')) {
                let included = response.included;
                response_object.files = [];
                included.forEach(function (included_item) {
                    //all files into one object
                    switch (included_item.type) {
                        case 'paragraph--paragraph_images':
                            response_object.forEach(function (response_object_item,response_object_index) {
                                response_object_item.field_paragraphs.forEach(function(paragraph_object,paragraph_object_index){
                                    if( paragraph_object.paragraph_id === included_item.id) {
                                        response_object[response_object_index].field_paragraphs[paragraph_object_index].images = included_item.relationships?.field_photos?.data;
                                    }
                                });
                            });
                            break;
                        case 'file--file':
                            response_object.files[included_item.id] = {
                                id: included_item.attributes.drupal_internal__fid,
                                formats: included_item.attributes.image_style_uri,
                                url: included_item.attributes.uri?.url
                            };
                            break;
                    }
                });
            }
            break;

        case 'speakers':
            if (response.data.length === 0) {
                return response.data;
            }
            response.data.forEach(function (item, index) {
                response_object.push({
                    id: item.id,
                    type: 'speakerspage',
                    title: item.attributes?.title,
                    text: item.attributes?.body?.processed,
                    text_bottom: item.attributes?.field_subtitle
                });
                if (item.relationships.hasOwnProperty('field_entity')) {
                    let entities = item.relationships.field_entity.data;
                    response_object[index].field_entity = [];

                    entities.forEach(function (entity_item) {
                        response_object[index].field_entity.push({
                            entity_id: entity_item.id,
                            entity_type: entity_item.type
                        });
                    });
                }
            });

            if (response.hasOwnProperty('included')) {
                let included = response.included;
                response_object.files = [];
                response_object.speakers = [];
                response_object.stands = [];

                included.forEach(function (included_item) {
                    //all files into one object
                    switch (included_item.type) {
                        case 'file--file':
                            response_object.files[included_item.id] = {
                                id: included_item.attributes.drupal_internal__fid,
                                formats: included_item.attributes.image_style_uri,
                                url: included_item.attributes.uri?.url
                            };
                            //}
                            break;
                        case 'node--speaker':
                            response_object.speakers[included_item.id] = {
                                id: included_item.attributes.drupal_internal__nid,
                                name: included_item.attributes?.title,
                                text: included_item.attributes?.body?.processed,
                                class: 'speaker'+included_item.attributes?.field_class,
                                image: included_item.relationships?.field_image?.data?.id
                            };
                            break;
                    }
                });
            }
            break;

        case 'live-streams':
            if (response.data.length === 0) {
                return response.data;
            }
            response_object.streams = [];
            response.data.forEach(function (item) {
                response_object.streams.push({
                    title: item.attributes.title,
                    body: item.attributes?.body?.processed,
                    slug: item.attributes?.field_slug
                });
            });
            break;
        case 'live-stream':
            if (response.data.length === 0) {
                return response.data;
            }

            response_object.livestream = {
                id: response.data[0].attributes.drupal_internal__nid,
                title: response.data[0].attributes.title,
                body: response.data[0].attributes.body?.processed
             };
            break;
        case 'question':
            if (response.data.length === 0) {
                return response.data;
            }
            let response_data = response.data[0];

            response_object.currentQuestion = {
                uid: response_data.id,
                id: response_data.attributes.drupal_internal__nid,
                title: response_data.attributes.title,
                question: response_data.attributes.body?.processed,
                background: response_data.attributes.field_question_bg,
                answers: response_data.attributes?.field_answers
            };
            break;

        case 'config':
            if (response.data.length === 0) {
                return response.data;
            }
            response_object.config = {
                id: response.data[0].attributes.drupal_internal__nid,
                body: response.data[0].attributes.body?.processed,
                show_banner: response.data[0].attributes?.field_show_banner
            };
            break;

        case 'stand':
            if (response.data.length === 0) {
                return response.data;
            }
            let response_obj = response.data[0];
            response_object.currentStand = {
                id: response_obj.attributes.drupal_internal__nid,
                tracking_script: response_obj.attributes.field_tracking_script,
                title: response_obj.attributes.title,
                body: response_obj.attributes.body?.processed,
                class: 'stands'+response_obj.attributes?.field_class,
                files: response_obj.relationships?.field_files?.data
            };

            if (response.hasOwnProperty('included')) {
                let included = response.included;
                response_object.files = [];

                included.forEach(function (included_item) {
                    switch (included_item.type) {
                        case 'file--file':
                            response_object.files[included_item.id] = {
                                id: included_item.attributes.drupal_internal__fid,
                                url: included_item.attributes.uri?.url
                            };
                            break;
                    }
                });
            }
            break;

        case 'world':
            if (response.data.length === 0) {
                return response.data;
            }

            if (response.hasOwnProperty('included')) {
                let included = response.included;
                response_object.files = [];
                response_object.currentStand = {};

                included.forEach(function (included_item) {
                    let worldUrl = included_item.attributes.field_url;
                    if( worldUrl && params.worldID ) {
                        worldUrl = worldUrl.replace(/\s+/g, '-').toLowerCase();
                        if ( worldUrl === params.worldID) {
                            //all files into one object

                            response.data.forEach(function (item) {
                                response_object.push({
                                    id: item.id,
                                    type: 'worldspage',
                                    title: item.attributes?.title,
                                    text: item.attributes?.body?.processed,
                                    text_bottom: item.attributes?.field_subtitle
                                });
                            });

                            response_object.currentStand = {
                                id: included_item.attributes.drupal_internal__nid,
                                tracking_script: included_item.attributes.field_tracking_script,
                                title: included_item.attributes.title,
                                body: included_item.attributes.body?.processed,
                                class: 'stands'+included_item.attributes?.field_class,
                                files: included_item.relationships?.field_files?.data
                            };
                        }
                    }

                    switch (included_item.type) {
                        case 'file--file':
                            response_object.files[included_item.id] = {
                                id: included_item.attributes.drupal_internal__fid,
                                formats: included_item.attributes.image_style_uri,
                                url: included_item.attributes.uri?.url
                            };
                        break;
                    }
                });
            }
        break;

        case 'contact_block':
            if (response.data.length === 0) {
                return response.data;
            }
            response.data.forEach(function (item) {
                response_object = {
                    id: item.id,
                    type: 'contact_block',
                    label: item.attributes.field_title?.value,
                    mail: item.attributes.field_mail,
                    question: item.attributes.field_question,
                    address: item.attributes.field_address,
                    text: item.attributes.body?.processed,
                    textBottom: item.attributes.field_textbottom?.value,
                    map: item.attributes.field_map
                };

            });
            break;
        case 'partners_block':
            if (response.data.length === 0) {
                return response.data;
            }
            response_object.files = [];
            if (response.hasOwnProperty('included')) {
                let included = response.included;

                included.forEach(function (included_item) {
                    //all files into one object
                    switch (included_item.type) {
                        case 'file--file':
                            response_object.files[included_item.id] = {
                                id: included_item.attributes.drupal_internal__fid,
                                formats: included_item.attributes.image_style_uri,
                                url: included_item.attributes.uri?.url
                            };
                            break;
                    }
                });
            }
            response.data.forEach(function (item) {
                response_object.id = item.id;
                response_object.type = 'partners_block';
                response_object.label = item.attributes.field_title?.value;

                let partners = [];
                for(let i = 1; i < 5; i++) {
                    let logos = [];
                    let images = item.relationships['field_logo_' + i]?.data;
                    if (images.length > 0){
                        images.forEach(function (image) {
                            let url = DRUPAL_API+response_object.files[image.id]?.url;
                            if(url) {
                                logos.push({
                                    id: image.id,
                                    alt: image.meta?.alt,
                                    img: url
                                });
                            }
                        });
                    }
                    partners.push({
                        label: item.attributes['field_label_'+i],
                        items: logos
                    });
                }
                response_object.partners = partners;
            });
            break;
        case 'menu':
            if (response.data.length === 0) {
                return response.data;
            }

            response.data.forEach(function (item, index) {
                response_object.push({
                    id: item.id,
                    type: 'menu-'+params.name,
                    url: item.attributes.url,
                    text: item.attributes.title
                });

                let url = item.attributes?.url;
                if( params.lang === 'en' && url.charAt(0) === '/' && url !== '/en' ){ url = '/en'+url;}
                if( url.charAt(0) === '#' && item.attributes?.options?.attributes?.home_page !== 'no' ){ url = '/'+params.lang+url; }

                if( url !== item.attributes?.url ){ response_object[index].url = url; }

                if( typeof item.attributes?.options?.attributes?.class !== 'undefined' && item.attributes?.options?.attributes?.class.indexOf('navlink') > -1 ){
                    response_object[index].url = '/'+(params.lang ==='en'?'cs':'en')
                }
                if( item.attributes?.options?.attributes?.target){
                    response_object[index].target = item.attributes?.options?.attributes?.target;
                }
                if( item.attributes?.options?.attributes?.class){
                    response_object[index].class = item.attributes?.options?.attributes?.class;
                }
            });
            response_object.items = response_object;
            break;
    }
    return response_object;

}


export function setFileFromUUID(uuid, files, fields = [], image_style = '' ){
    if( files.hasOwnProperty(uuid)){
        let file = files[uuid];
        file.url = DRUPAL_API+file.url;

        if(image_style){
            if(file.formats?.hasOwnProperty(image_style)){
                file.url = file.formats[image_style];
            }
        }
        if( fields.length > 0 ){
            let file_tmp = {};
            fields.forEach(function(field){
                if( file.hasOwnProperty(field) ){
                    file_tmp[field] = file[field];
                }
            });
            file = file_tmp;
        }
        return file;
    }
    return null;
}

export function formatDate(date, format, months = {}){
    let event_date = new Date(date);
    let mm = event_date.getMonth() + 1; // getMonth() is zero-based
    let dd = event_date.getDate();
    let yy = event_date.getFullYear();
    return format.replace('d',dd).replace('Y',yy).replace('M',months[mm]);
}

export function setProgramCategoryFromUUID(uuid,categories){
    if( categories.hasOwnProperty(uuid)) {
        let category = categories[uuid];
        return category.name;
    }
    return null;
}

export function setRowFromUUID(uuid, rows){
    if( rows.hasOwnProperty(uuid)) {
        return rows[uuid];
    }
    return null;
}

export function removeTags(str) {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
}

export function hashValue(value){
    let return_data = encode(value);
    return return_data;
}

export function getQuestionCountFromCookie(){
    let user_data = Cookies.get(hashValue('wppexpo_data'));
    let user_data_json = JSON.parse(user_data);
    if ( user_data_json.hasOwnProperty(hashValue('questions'))) {
        return user_data_json[hashValue('questions')].length;
    }
    return 0;
}