import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import MapItem from "../components/MapItem.js";

export default function Map( {staticData} ) {
  
  const { pathname } = useLocation();

  const MapsList = staticData.maps.map( item => {
    return <MapItem key={ item.label } staticData={ item } />
  })

  // const files = staticData.maps.map( (file, index) => {
  //   return <div className="btn-hero" key={index}>
  //       <a href={file.url} target="_blank">{file.text}</a>
  //   </div>;
  // })


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return(
    <section id="maps" className="section-maps">
    <div className="container">
      <h2>{ staticData.pageInfo.title }</h2>
      <div data-aos-duration="1200" data-aos="fade-right" className="aos-init" dangerouslySetInnerHTML={{__html: staticData.body}}>
      </div>
      { MapsList }
    </div>
  </section>
  )
}