import React from 'react';
import ReactDOM from 'react-dom/client';
import App, { loader as rootLoader } from './App';
import HomePage, { loader as homepageLoader } from './routes/HomePage.js'
import SpeakersPage, { loader as speakersLoader } from './routes/SpeakersPage.js'
import WorldPage, { loader as worldLoader } from './routes/WorldPage.js'
import QuestionPage, {loader as questionLoader } from "./routes/QuestionPage";
import VideoPage, { loader as videoLoader } from "./routes/VideoPage";

import MapPage, { loader as mapLoader } from './routes/MapPage.js'
import LiveStreamPage, { loader as liveStreamLoader } from './routes/LiveStreamPage.js';

import ErrorPage from './routes/ErrorPage';
import ErrorBoundary from "./components/ErrorBoundary";


import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import ProgramPage, { loader as programLoader} from "./routes/ProgramPage";
import PhotogalleryPage, {loader as photogalleryLoader} from "./routes/PhotogalleryPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorBoundary />,
        loader: rootLoader,
        children: [
            {
                path: "/:lang?",
                element: <HomePage />,
                loader: homepageLoader
            },
            {
                 path: ":lang?/speakers",
                 element: <SpeakersPage />,
                 loader: speakersLoader
            },
            {
                path: ":lang?/map",
                element: <MapPage />,
                loader: mapLoader
           },
            {
                path: ":lang?/live/:stage?",
                element: <LiveStreamPage />,
                loader: liveStreamLoader
            },
            {
                path: ":lang?/world/:worldID?",
                element: <WorldPage />,
                loader: worldLoader
           },
            {
                path: ":lang?/game/:questionID?",
                element: <QuestionPage />,
                loader: questionLoader
            },
            {
                path: ":lang?/recordings_tmp",
                element: <VideoPage />,
                loader: videoLoader
            },
            {
                path: ":lang?/recordings",
                element: <ProgramPage />,
                loader: programLoader
            },
            {
                path: ":lang?/gallery",
                element: <PhotogalleryPage />,
                loader: photogalleryLoader
            },
            /*,
            {
                path: "/:lang?/speakers",
                element: <SpeakersPage />,
                loader: speakersLoader
            }*/
        ]
    },
    {
        path: "/:lang",
        element: <App />,
        errorElement: <ErrorPage />,
        loader: rootLoader,
        children: [
            {
                path: "/:lang",
                element: <HomePage />,
                loader: homepageLoader
            }]

    }

])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={ router } />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
