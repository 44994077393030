import React, { useState } from "react";
import { NavLink } from "react-router-dom";

export default function Header( { staticData } ) {

  const [menuOpened, setMenuOpened] = useState(false);

  function toggleMenu() {
    setMenuOpened( prev => prev = !prev );
  }

  return(
      <header>
        <nav className={`nav-main ${menuOpened ? "nav-open" : false}`}>
          <div className="nav-logo">
            <a href="/"><img src="/assets/images/WPP-logo.svg" alt="WPP Logo"/></a>
          </div>
          <ul className="nav-list">

            {(staticData.hasOwnProperty('items') && staticData.items.length > 0) && staticData.items.map(item => {
              return <li key={ item.text } className={`nav-item ${item.class ? item.class : ""}`}>
                { item.navlink ? <NavLink to={item.url}>{item.text}</NavLink> : <a target={`${item.target ? item.target : "_self"}`} href={item.url}>{ item.text }</a> }
              </li> }) }
          </ul>

          <button onClick={ toggleMenu } className="nav-icon">
            <span></span>
            <span></span>
            <span></span>
          </button>
        </nav>
      </header>
  )
}