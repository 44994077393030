import React, { useEffect } from "react";
import AOS from 'aos';

import fetchApi, {setFileFromUUID, formatDate, setRowFromUUID, setProgramCategoryFromUUID} from '../api.js';

import {useLoaderData} from "react-router-dom";
import Hero from '../components/Hero.js'
import Countdown from '../components/Countdown.js'
import About from '../components/About.js'
import Program from '../components/Program.js'
import Speakers from '../components/Speakers.js'
import Stands from '../components/Stands.js'

import staticDataJS from '../staticData.js'
import staticDataJSEN from '../staticDataEN.js'
import textConfig from '../textConfig.js'

function prepareData(content,textConfigLocal={}){
    let returned_data = {}

    let paragraphs = content[0].field_paragraphs;
    paragraphs.forEach(function (item){
        switch (item.field_display_style){
            case 'header':
                const formatted_date = formatDate(item.event_date, textConfigLocal.time.format, textConfigLocal.months);
                returned_data.hero = {
                    videoUrl: setFileFromUUID(item.field_video, content.files, ['url'])?.url,
                    image: setFileFromUUID(item.field_image,content.files,['url','id']),
                    logo: item.field_title,
                    logoText: item.field_subtitle,
                    text: item.body,
                    date: formatted_date,
                    btn: {
                        label: item.field_link?.title,
                        url: item.field_link?.uri
                    }
                };
                break;
            case 'about':
                returned_data.about = {
                        side: item.field_title,
                        title: item.field_subtitle,
                        text: item.body,
                        video_embed: item.video_embed,
                    };
                break;
            case 'countdown':

                returned_data.countdown = {
                        label: item.field_title,
                        labelBottom: item.body,
                        time: textConfigLocal.time,
                        timestamp: new Date(item.event_date).getTime() /*Math.floor(new Date(item.event_date).getTime() / 1000)*/
                    };
                break;
            case 'program':
                let categories = [];
                item.program_rows.forEach(function(program_row_item){
                    let program_row = setRowFromUUID(program_row_item.id, content.program_rows);
                    let category = program_row.category;
                    if( typeof categories[category] == 'undefined' ){
                        categories[category] = [];
                    }
                    categories[category].push(program_row);
                });

                let program = [];
                let navigation = [];
                for (const [category, rows] of Object.entries(categories)) {
                    const category_items = [];
                    rows.forEach(function(row){
                        category_items.push({
                            place: row.place,
                            time: row.time,
                            label: row.title,
                            text: row.body,
                            detail: row.detail,
                            link: {
                                text: row.link?.title,
                                url: row.link?.uri.replace('internal:','')
                            }
                        });
                    });
                    let category_name = setProgramCategoryFromUUID(category,content.program_categories);
                    program.push({
                      type: category_name,
                      type_id: category,
                      items: category_items
                    });
                    navigation.push({
                        btn: {
                            label: category_name,
                            url: "#"+category
                        }
                    });
                }
                returned_data.program = {
                    title: item.field_title,
                    text: item.body,
                    program: program,
                    navigation: navigation
                };
                break;
            case 'bricks':
                let stands = [];
                item.stands_rows.forEach(function(stand_row) {
                    let stands_row = setRowFromUUID(stand_row.id, content.stands);
                    let pdfs = [];

                    
                    stands_row.files.forEach(function(file){
                        let pdf = setFileFromUUID(file.id, content.files, ['url'])?.url;
                        pdfs.push({url:pdf,text: textConfigLocal.download});
                    });
                    stands.push({
                        label: stands_row.title,
                        text: stands_row.body,
                        class:stands_row.class,
                        url: stands_row.url,
                        files: pdfs,
                        show_link: stands_row.show_link,
                        more: textConfigLocal.more
                    });
                });
                returned_data.stands = {
                    label: item.field_title,
                    text: item.body,
                    stands: stands
                };
                break;

                case 'speakers':
                    let speakers = [];
                    item.speakers_rows.forEach(function(speaker_row) {
                      let speakers_row = setRowFromUUID(speaker_row.id, content.speakers);
                      speakers.push({
                          name: speakers_row.name,
                          text: speakers_row.text,
                          class:speakers_row.class,
                          image: {
                            url: setFileFromUUID(speakers_row.image, content.files, ['url'], 'wide').url
                          }
                      });
                  });
                  returned_data.speakers = {
                      title: item.field_title,
                      text: item.body,
                      text_bottom: item.field_subtitle,
                      items: speakers
                  };
                  break;
        }
    });
    return returned_data;
}

export async function loader ({params}) {
    let staticData = await staticDataJS;
    let lang = 'cs';
    let textConfigLocal = textConfig[lang];
    if( params.hasOwnProperty('lang') ){
        if( params.lang == 'en' ){
            lang = params.lang;
            textConfigLocal = textConfig[lang];
            staticData = await staticDataJSEN;
        }
    }
    try {
        let content = await fetchApi({type: 'homepage', lang: lang});
        content = prepareData(content, textConfigLocal);
        content.textConfig = textConfigLocal;
        return {static_data: staticData.pages.homepage, content_data: content};
    } catch (e){
        return {static_data: staticData.pages.homepage, content_data: {}};
    }
}



export default function Home({data}) {
    const dataArray = useLoaderData();
    //const staticData = dataArray.static_data;
    const content = dataArray.content_data;
  useEffect(() => {
    AOS.init();
  }, [])

  let ImportedComponents = {
    Hero,
    Countdown,
    About,
    Program,
    Speakers,
    Stands
}

    let page = [];
    for (const [key, value] of Object.entries(content)) {
        let element = key.charAt(0).toUpperCase() + key.slice(1);
        page.push({ element: element, value: value});
    }
    
    let components = page.map((value) => {
        if( ImportedComponents[value.element] ) {
            let el = React.createElement(ImportedComponents[value.element], {
                staticData: value.value,
                textConfig: content.textConfig,
                key: value.element,
            }, null);
            return el;
        }
    })

  return(
        <>
            { components }
          {/* <Hero staticData={ content.hero } />
          <Countdown staticData={ content.countdown } />
          <About staticData={ content.about } />
          <Program staticData={ staticData.program } />
          <Stands staticData={ staticData.stands } />
          <Speakers staticData={staticData.speakers} /> */}
          </>
  )
}