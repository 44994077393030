import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";


export default function LiveStream( {staticData} ) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return(
    <section className="section-live">
    <div className="container">
      <h1 data-aos-duration="1200" data-aos="fade-right" className="aos-init">{ staticData.title }</h1>
      <div data-aos-duration="1200" data-aos="fade-right" className="aos-init" dangerouslySetInnerHTML={{__html: staticData.body}}>

      </div>
    </div>
  </section>
  )
}