import React from "react";
import PartnersItem from './PartnersItem';

export default function PartnersList( { staticData } ) {

  const partnersItems = staticData.items.map( (item, index) => {
    return <PartnersItem key={ index } staticData={ item } />
  })

  return(
    <>
      <h3>{ staticData.label }</h3>
      <ul className="partners-list">
          { partnersItems }
      </ul>
    </>
  )
}

