import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";


export default function Stand( {staticData} ) {  
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  let sectionClasses = [
    "section-stand",
    staticData.class,
    staticData.config.show_banner ? 'has-banner' : null
  ]

  return(
    <section className={ sectionClasses.join(" ") }>
    <div className="container">
      <h1 data-aos-duration="1200" data-aos="fade-right" className="aos-init">{ staticData.title }</h1>
      <div data-aos-duration="1200" data-aos="fade-right" className="aos-init" dangerouslySetInnerHTML={{__html: staticData.body}}>
        
      </div>

      {staticData.pdfs.length > 0 ?
          (
              staticData.pdfs.map((file, index) => (
                  <div className="btn-hero" key={index}>
                    <a href={file.url} target="_blank">{file.text}</a>
                  </div>
              ))
          ):('')
      }

    </div>

    { staticData.config.show_banner && <div className="stand-banner" dangerouslySetInnerHTML={{__html: staticData.config.body }}>
    </div> }

    { staticData.tracking_script &&
        <script src={ staticData.tracking_script } type="text/javascript"></script>
    }
  </section>
  )
}