const data = {
  pages: {
    homepage: {
      hero: {
        videoUrl: "../assets/images/campus.mp4",
        logo: "WPP",
        logoText: "EXPO",
        text: "Worlds of Marketing",
        date: "21<sup>st</sup> June 2023",
        btn: {
          label: "REGISTER",
          url: "https://www.eventbrite.com/e/wpp-expo-worlds-of-marketing-tickets-577032548887"
        }
      },
      countdown: {
        label: "To enter 9 amazing marketing worlds",
        labelBottom: "to go until the day when 9 amazing worlds beyond everyday marketing open up to you",
        time: {
          day: "days",
          hour: "hours",
          minute: "minutes",
          second: "seconds"
        }
      },
      about: {
        side: "WPP EXPO",
        title: "Going beyond the boundaries of everyday marketing ",
        text: "Plan a discovery mission through the 9 worlds of marketing. Be inspired by talks from remarkable speakers.  Map media and technology innovations at partner booths. Have fun at side events. Think of the vast terrace of the WPP Campus. It has a breathtaking panorama, a super vibe, it's a great place for networking, and, together with the BBQ party, enhances everything that Worlds of Marketing will bring you."
      },
      program: {
        title: "Program",
        text: "World of Brand & Strategy, World of Consumer & Insights, World of Future, and 6 other amazing worlds, plus talks by notable personalities, media and technology exhibitions, plus plenty of networking opportunities at the accompanying events.",
        navigation: [
          {
            btn: {
              label: "WORLDS OF MARKETING",
              url: "talks"
            }
          },
          {
            btn: {
              label: "EXPOSITION",
              url: "expo"
            }
          },
          {
            btn: {
              label: "Other activities",
              url: "others"
            }
          }
        ],
        program: [
          {
            type: "Talks",
            id: "talks",
            items: [
              {
                place: "Posluchárna, Mezanin",
                time: "9:30 – 18:00",
                label: "Series of lectures on marketing topics",
                text: "We will be informing you soon about the individual lectures and our final line-up of remarkable speakers.",
                link: {
                  text: "List of speakers",
                  url: "#speakers"
                }
              },
            ]
          },
          {
            type: "Expo",
            id: "expo",
            items: [
              {
                place: "Krmeetko",
                time: "9:00-18:00",
                label: "EXPOSITION",
                text: "Map out media and technology innovations in attractive WPP Expo partner stands",
                link: {
                  text: "List of partners",
                  url: "#partners"
                }
              },
              {
                place: "Krmeetko",
                time: "9:00-18:00",
                label: "WORLDS OF MARKETING",
                text: "",
                link: {
                  text: "Worlds of marketing",
                  url: "#stands"
                }
              },
            ]
          },
          {
            type: "Other activities",
            id: "others",
            items: [
              {
                time: "11:00, 14:00, 16:00",
                label: "GUIDED TOUR OF THE CAMPUS",
                text: "Uncover the secrets of the building. Join a guided tour with David Lhota and architect Marek Tichý.",
              },
              {
                time: "11:00, 14:00",
                label: "EXCURSION TO THE RESEARCH KITCHEN",
                text: "Have a look into a modern focus group study, where the best researchers mine deep insights.",
              }
            ]
          }
        ]
      },

      stands: {
        label: "Worlds of Marketing",
        text: "Plan your discovery mission to the 9 Worlds of Marketing",
        stands: [
          {
            label: "World of Brand & Strategy",
            text: "Does your brand lack luster? Come take a look at a world where we'll tell you how to make it shine, make it glow again, and show it off so that no one can miss it. Let your brand shine!",
            class: "stands-bg--brand"
          },
          {
            label: "World of Consumer & Insights",
            text: "The world of customers, users, consumers. An endless story of discovering what they think, what they want, how they behave, and what shapes their opinions.  Why do they prefer brand A to X, what arguments do they listen to, and what do they believe?",
            class: "stands-bg--consumer"
          },
          {
            label: "World of Future",
            text: "How much will AI change marketing? Why should blockchain interest marketers? What does hybrid reality look like? And why all these questions should be addressed today – you'll find out in Future World.",
            class: "stands-bg--future"
          },
          {
            label: "World of Data & Analytics",
            text: "Interested in learning more about customers, understanding their behaviours and attitudes in both the online and offline worlds? We'll show you how to analyse, interpret, visualize, and predict customer data and future behaviour.",
            class: "stands-bg--data"
          },
          {
            label: "World of Media",
            text: "The over-used phrase says that 'content is king'. However, even the best advertising that no one sees misses the point. It's like the emperor's new clothes. This world will be entirely devoted to the queen of communication – media and media strategy.",
            class: "stands-bg--media"
          },
          {
            label: "World of Personalisation",
            text: "The customer wants us to understand them, and personalisation is what allows us to do that. We're transforming the way we reach out to and engage with customers. Discover the power of a targeted approach. Dedicate yourself to personalisation and achieve success!",
            class: "stands-bg--person"
          },
          {
            label: "World of Creativity",
            text: "Creativity. A mysterious spell? And what does it really mean? This world will guide you through the creative process, prove that creativity helps sell, and inspire you to see how brands can use creativity to change the world for the better.",
            class: "stands-bg--creativity"
          },
          {
            label: "World of Content Marketing",
            text: "How do you prepare the best content for integrated campaigns, social media, organic and paid PR, plus perfectly optimized through SEO? We cover it all!",
            class: "stands-bg--content"
          },
          {
            label: "World of ESG",
            text: "Brand decisions have never been so consequential -  the challenges we face are existential –  inequity, climate crisis, war. How can we unlock ESG as your brand’s superpower and drive sustainable growth?",
            class: "stands-bg--sustain"
          }
        ]
      },
    },
    speakerspage: {
      title: "Speakers of WPP EXPO",
      text: "Inspiring figures of Czech marketing will share their experiences and present their perspective on the most interesting marketing trends<br /><br />Among others, you will meet the following experts:",
      text_bottom: "More speakers will be introduced soon",
      items: [
        {
          name: "Jan Los XX",
          text: "Chief Marketing & Digital Officer<br />L'Oréal",
          class: "speaker-bg--brand",
          delay: 0,
          image: {
            url: "assets/images/Jan_Los.jpg"
          }
        },
        {
          name: "Mary Kyriakidi",
          text: "Global Thought Leader<br />Kantar",
          class: "speaker-bg--consumer",
          delay: 300,
          image: {
            url: "assets/images/Mary_Kyriakidi.jpg"
          }
        },
        {
          name: "Ladislav Báča",
          text: "Director of Brand & Marketing Communication<br />T-Mobile CZ",
          class: "speaker-bg--future",
          delay: 600,
          image: {
            url: "assets/images/Ladislav_Baca.jpg"
          }
        },
        {
          name: "Jan Los 2",
          text: "Chief Marketing & Digital Officer<br />L'Oréal",
          class: "speaker-bg--brand",
          delay: 0,
          image: {
            url: "assets/images/Jan_Los.jpg"
          }
        },
        {
          name: "Mary Kyriakidi 2",
          text: "Global Thought Leader<br />Kantar",
          class: "speaker-bg--consumer",
          delay: 300,
          image: {
            url: "assets/images/Mary_Kyriakidi.jpg"
          }
        },
        {
          name: "Ladislav Báča 2",
          text: "Director of Brand & Marketing Communication<br />T-Mobile CZ",
          class: "speaker-bg--future",
          delay: 600,
          image: {
            url: "assets/images/Ladislav_Baca.jpg"
          }
        }
      ]
    }
  },

  header: {
    logo: {
      url: "/assets/images/WPP-logo.svg",
    },
    links: [
      {
        url: "#about",
        text: "About WPP EXPO"
      },
      {
        url: "#program",
        text: "Program"
      },
      {
        url: "#stands",
        text: "Worlds of Marketing"
      },
      {
        url: "/en/speakers",
        text: "Speakers"
      },
      {
        url: "#contact",
        text: "Contact"
      },
      {
        url: "#partners",
        text: "Partners"
      },
      {
        url: "/cs",
        text: "CS",
        navlink: true
      },
      {
        url: "https://www.eventbrite.com/e/wpp-expo-worlds-of-marketing-tickets-577032548887",
        text: "Register",
        class: "btn-nav",
        target: "_blank"
      }
    ]
  },
  register: {

  },
  partners: {
    label: "Partners WPP EXPO",
    text: "Partners",
    partners: [
      {
        label: "Main partner",
        items: [
          {
            img: "../assets/images/logo/nova.svg"
          }
        ]
      },
      {
        label: "Partners",
        items: [
          {
            img: "../assets/images/logo/adobe.svg"
          },
          {
            img: "../assets/images/logo/bigmedia.png"
          },
          {
            img: "../assets/images/logo/cnc.png"
          },
          {
            img: "../assets/images/logo/seznam.svg"
          },
          {
            img: "../assets/images/logo/bloomreach.png"
          },
          {
            img: "../assets/images/logo/salesforce.png"
          },
          {
            img: "../assets/images/logo/tiktok.png"
          },
        ]
      },
      {
        label: "Visitors‘ refreshment is taken care of by",
        items: [
          {
            img: "../assets/images/logo/cocacola.png"
          }
        ]
      },
      {
        label: "Transport for visitor is provide by",
        items: [
          {
            img: "../assets/images/logo/liftago.png"
          }
        ]
      }
    ]
  },
  contact: {
    label: "Contact",
    mail: "wppexpo@wpp.com",
    question: "Do you have any questions?",
    address: "WPP EXPO: Worlds of Marketing<br />Bubenská 1<br />170 00  Praha 7<br />wpp EXPO@wpp.com",
    text: "Clients of WPP group companies can contact their account manager directly.",
    textBottom: "We look forward to seeing you in the Palace of the former Electric Companies (now WPP Campus) right next to the Vltavská metro station.",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2559.328505357945!2d14.434761615689805!3d50.09885787942843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b95251e6453f5%3A0x3fee3b9c0355374b!2sWPP%20Campus!5e0!3m2!1sen!2scz!4v1681220430962!5m2!1sen!2scz"
  },
  footer: {
    items: [
      {
        url: "https://www.eventbrite.com/e/wpp-expo-worlds-of-marketing-tickets-577032548887",
        text: "Register",
        target: "_blank"
      },
      {
        url: "#program",
        text: "Program"
      },
      {
        url: "#stands",
        text: "Stands"
      },
      {
        url: "#speakers",
        text: "Speakers"
      },
      {
        url: "#partners",
        text: "Partners"
      },
      {
        url: "#contact",
        text: "Contact"
      }
    ]
  }
  
}

export default data;