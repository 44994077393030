import React from "react";

import { Player } from "video-react";
import { useRef } from "react";


export default function Hero( {staticData} ) {
  const videoRef= useRef();
  const setPlayBack = () => {
    videoRef.current.playbackRate = .5;
  };

  return(
    <section className="section-hero">
      <Player ref={videoRef} onCanPlay={() => setPlayBack()}
          playsInline autoPlay play muted loop={true}
          src={ staticData.videoUrl }
        >
      </Player>
      {/* <video playsInline autoPlay muted loop poster="placeholder.jpg" id="bgvideo" width="x" height="y">
        <source src={ staticData.videoUrl } type="video/mp4" />
      </video>     */}
      <div className="container">
        <h1>
          <span data-aos="fade-in" data-aos-delay="500" data-aos-duration="2200" className="hero-top">
            <span className="hero-logo">WPP</span>
            <span className="hero-text">{staticData.logoText}</span>
          </span>
          <span data-aos="fade-in" data-aos-delay="1200" data-aos-duration="2200" className="hero-bottom">{ staticData.text }</span>
          <span data-aos="fade-in" data-aos-delay="1200" data-aos-duration="2200" className="hero-date" dangerouslySetInnerHTML={{__html: staticData.date}} ></span>
          
       </h1>
        { staticData.btn.label && 
        <div data-aos="fade-in" data-aos-delay="1200" data-aos-duration="2200" className="btn-hero">
          <a rel="noreferrer" target="_blank" href={staticData.btn.url} >
            { staticData.btn.label }
          </a>
        </div>
        }
      </div>
    </section>
  )
}