import React, { useState } from "react";

export default function ProgramItem( { staticData, textConfig } ) {

  let [modal, showModal] = useState(false);

  let toggleModal = () => {
    showModal( (prev) => modal = !prev );
  }

  return(
    
    <li data-aos-duration="1200" data-aos="fade-right" className="aos-init">
    <div className="program-col">
      <div className="program-time">{ staticData.time }</div>
      { staticData.place ? <div className="program-place">{ staticData.place }</div> : false }
    </div>
    <div className="program-col">
      <h3 className="program-label">
        { staticData.label }
      </h3>
      {/* <ul className="program-tags">
        <li>#veletrh</li>
      </ul> */}
    </div>
    <div className="program-col">
        <div className="program-text" dangerouslySetInnerHTML={{__html: staticData.text}}>
        </div>
      { staticData.link?.url ? <a href={staticData.link.url}>{ staticData.link.text }</a> : false }
      { staticData.detail && <a href={"/"+textConfig.lang+"/recordings"}>{ textConfig.record }</a> }
    </div>

      {/*<div className={"program-detail" + (modal ? " active" : "")}>
        { modal && <div className="program-detail-inner" dangerouslySetInnerHTML={{__html: staticData.detail}} /> }
      </div> 
    
    { staticData.detail &&
        <button onClick={ toggleModal } className={"program-detail-button" + (modal ? " active" : "")} >Detail</button>
    }*/}
  </li>
  )
}