import React from "react";

export default function Footer( {staticData} ) {
  return(
      <footer>
        <div className="container">
          <div className="footer-links">
            <ul>
              {(staticData.hasOwnProperty('items') && staticData.items.length > 0) && staticData.items.map(item => {
                return <li key={item.text}><a target={`${item.target ? item.target : "_self"}`} href={item.url}>{item.text}</a>
                </li> }) }
            </ul>
          </div>
          <div className="footer-side">
            <ul>
                {(staticData.hasOwnProperty('side') && staticData.side.length > 0) && staticData.side.map(item => {
                    let show_link = true;
                    if(item.class){
                        item.class.forEach(function(classitem){
                            if( classitem.startsWith('only-') ) {
                                show_link = false;
                                if (classitem.replace('only-', '') === staticData.lang) {
                                    show_link = true;
                                }
                            }
                        });
                    }

                    if( show_link === true ) {
                        if (item.url === '') {
                            return <li key={item.text}>{item.text}</li>
                        } else {
                            return <li key={item.text}><a target={`${item.target ? item.target : "_self"}`}
                                                          href={item.url}>{item.text}</a>
                            </li>
                        }
                    }
                }) }
            </ul>
          </div>
        </div>
      </footer>
  )
}