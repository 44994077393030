import React from "react";
import ProgramItem from "./ProgramItem";
import { Link } from "react-router-dom";


export default function StandItem( { staticData } ) {
  return(
    <li className={`${staticData.class ? staticData.class : ''}`} >
        <Link to={"world/"+staticData.url}>
          <h3 className="stands-label">
          { staticData.label }
          </h3>
          <div className="stands-text" dangerouslySetInnerHTML={{__html: staticData.text}}>
          </div>
          { staticData.show_link === true && (
            <div className="btn-hero"><span>{ staticData.more }</span></div>
          )}
        </Link>
  </li>
  )
}

