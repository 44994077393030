import {React, useEffect, useState} from 'react';
import './scss/style.scss';
import {Outlet, useLoaderData} from 'react-router-dom';
import Header from './components/Header.js'
import Footer from './components/Footer.js'
// import Register from './components/Register.js'
import Partners from './components/Partners.js'
import Contact from './components/Contact.js'
import staticDataJS from './staticData.js'
import staticDataJSEN from './staticDataEN.js'
import textConfig from "./textConfig";
import fetchApi from "./api";
import ErrorBoundary from "./components/ErrorBoundary.js";
import ErrorPage from "./routes/ErrorPage";
import ScrollTopButton from './components/ScrollTopButton';


export async function loader ({params}) {

    let staticData = await staticDataJS;
    let lang = 'cs';

    if (params.hasOwnProperty('lang')) {
        if (params.lang == 'en') {
            lang = params.lang;
            staticData = await staticDataJSEN;
        }
    }
    //let textConfigLocal = textConfig[lang];
    let content = {};
    try {

        content.contact = await fetchApi({type: 'contact_block', lang: lang});
        content.partners = await fetchApi({type: 'partners_block', lang: lang});
        content.footer = {};
        content.footer.lang = lang;
        content.footer.items = await fetchApi({type: 'menu', name: 'footer', lang: lang});
        content.footer.side = await fetchApi({type: 'menu', name: 'footer_2', lang: lang});
        content.header = await fetchApi({type: 'menu', name: 'header', lang: lang});

        return {static_data: staticData, content_data: content};

    } catch (e) {
        return e;
    }

}

function App() {

  let [scrollTopButton, setScrollTopButton] = useState(false);

  useEffect(() => {
    window.location.hash = window.decodeURIComponent(window.location.hash);
    const scrollToAnchor = () => {
      const hashParts = window.location.hash.split('#');
      if (hashParts.length > 0) {
        const hash = hashParts.slice(-1)[0];
        const section = document.getElementById(hash);
        if ( section != null ) {
          section.scrollIntoView();
        }
      }
    };

    setTimeout(() => {
      scrollToAnchor();
    }, 300);
    window.onhashchange = scrollToAnchor;

    const scrollTopButtonVisibilty = () => {
        window.pageYOffset > 700 ? setScrollTopButton( true) : setScrollTopButton (false);
    }

    window.addEventListener('scroll', scrollTopButtonVisibilty);

    return () => {
        window.removeEventListener('scroll', scrollTopButton);
    }
    

  }, [])

    const dataArray = useLoaderData();
    if( dataArray.hasOwnProperty('content_data')) {
        const staticData = dataArray.static_data;
        const content = dataArray.content_data;

        return (
            <div className="App">
                <ErrorBoundary>
                    <Header staticData={content.header}/>
                    <main>
                        <Outlet/>
                        <Partners staticData={content.partners}/>
                        <Contact staticData={content.contact}/>
                        { scrollTopButton && ( <ScrollTopButton /> ) }
                    </main>
                    <Footer staticData={content.footer}/>
                </ErrorBoundary>
            </div>
        );
    } else {

        return(
            <div className="App">
                <ErrorPage ManualError={dataArray}/>
            </div>
        );
    }
}

export default App;
