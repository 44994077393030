import { useRouteError } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function ErrorPage({ManualError}) {
  let error = useRouteError();
  if( typeof error == 'undefined'){
      error = ManualError;
  }
  const navigate = useNavigate();
  if( error ) {
    return (
        <div className="error">
          <h3>{error.hasOwnProperty('status') && error.status}</h3>
          <p>{error.statusText || error.message}</p>
          <div className="btn-hero">
            <a onClick={() => navigate(-1)} href="#">
              Back
            </a>
          </div>
        </div>
    )
  }

  return (
      <div className="error">
        <h3>Error</h3>
      </div>
  )
}