import React, { useEffect } from "react";
import AOS from 'aos';

import fetchApi from '../api.js';
import {Link, useLoaderData} from "react-router-dom";

import textConfig from '../textConfig.js'
import LiveStream from "../components/LiveStream";

export async function loader ({params}) {
    let lang = 'cs';
    let textConfigLocal = textConfig[lang];
    if( params.hasOwnProperty('lang') ){
        if( params.lang == 'en' ){
            lang = params.lang;
            textConfigLocal = textConfig[lang];
        }
    }
    if( params.hasOwnProperty('stage')) {
        let content = await fetchApi({type: 'live-stream', lang: lang, stage: params.stage});
        return { content: content, lang: lang };
    }

    let content = await fetchApi({type: 'live-streams', lang: lang});
    return { content: content, lang: lang };
}



export default function LiveStreamPage({data}) {
    const dataArray = useLoaderData();
    const content = dataArray.content;
    const lang = dataArray.lang;
    useEffect(() => {
        AOS.init();
    }, [])

    if( content.hasOwnProperty('streams') ){
        return (
            <section id="livestream" className="section-livestream">
                <div className="container">
                    { content.streams.map((stream) => {
                        return <Link key={stream.title} className="livestream-link" to={'/'+lang+'/live/'+stream.slug}>
                            <span>{stream.title}</span>
                        </Link>
                    })
                    }
                </div>
            </section>
        )
    } else if( content.hasOwnProperty('livestream') ) {
        return <LiveStream staticData={content.livestream} />
    }

}