import React, { useEffect, useState, useRef } from "react";
import AOS from 'aos';

import fetchApi,{ hashValue } from '../api.js';
import {Link, useLoaderData, useNavigate} from "react-router-dom";

import Cookies from 'js-cookie';


export async function loader ({params}) {
    let lang = 'cs';
    if( params.hasOwnProperty('lang') ){
        if( params.lang == 'en' ){
            lang = params.lang;
        }
    }

    let content = await fetchApi({type: 'videopage', lang: lang});
    return { content: content, lang: lang };
}

export default function VideoPage({data}) {
    const dataArray = useLoaderData();
    const [ loggedIn, setLoggedIn] = useState(false);
    const [ wrongPass, setWrongPass] = useState(false);
    const inputRef = useRef(null);
    const navigate = useNavigate();

    const content = dataArray.content[0];
    let body = content.body.replaceAll('<iframe src="/','<iframe src="'+process.env.REACT_APP_DRUPAL_API+'/');
    useEffect(() => {
        AOS.init();
        checkPassoword();
    }, [])

    const checkPassoword = (e) => {
      let currentPass = Cookies.get(hashValue('wppexpo_pass'));

      if( currentPass == process.env.REACT_APP_PASS) {
        Cookies.set(hashValue('wppexpo_pass'), currentPass, {expires: 1});
        setLoggedIn(true);
      }
      if( hashValue(inputRef.current.value) == process.env.REACT_APP_PASS) {
        Cookies.set(hashValue('wppexpo_pass'), hashValue(inputRef.current.value), {expires: 1});
        setLoggedIn(true)
      }
      
      if( hashValue(inputRef.current.value) != '' &&  hashValue(inputRef.current.value) != process.env.REACT_APP_PASS ) {
        setWrongPass(true);
      }
    }

    const checkKey = (e) => {
      if( e.key == "Enter") {
        checkPassoword();
      }
    }

    if( loggedIn ) {
      return (
        <section id="videopage" className="section-videos">
          <div className="container">
            <h1>{ content.title }</h1>
            <div dangerouslySetInnerHTML={{__html: body }}></div>
          </div>
        </section>
      )
    } else {
        return(
          <section id="videopage" className="section-videos">
            <div className="container">
                <h1>{ content.title }</h1>
                <h2>Zadejte heslo</h2>
                <div className="form-input-text">
                  <input onKeyUp={ checkKey } ref={inputRef} type="password" />
                  { wrongPass && <div className="input-error">Nespravne heslo</div>}
                </div>
                <div className="buttons">
                  <div onClick={ checkPassoword } className="btn-hero"><span>Odeslat</span></div>
                  <div onClick={() => navigate(-1)} className="btn-hero"><span>Back</span></div>
                </div>
            </div>
         </section>
        );
    }
}