import React, { useEffect } from "react";
import AOS from 'aos';

import { useLoaderData } from "react-router-dom";

import staticDataJS from '../staticData.js'
import Speakers from '../components/Speakers.js'
import staticDataJSEN from "../staticDataEN";
import textConfig from "../textConfig";
import fetchApi, {setFileFromUUID} from "../api";
import ErrorPage from "./ErrorPage";

function prepareData(content,textConfigLocal){
    let returned_data = {}

    returned_data = content[0];
    returned_data.items = [];
    content[0].field_entity.forEach(function(item){
        let speaker = content.speakers[item.entity_id];
        speaker.image = {
            url: setFileFromUUID(speaker.image, content.files, ['url']).url
        };
        returned_data.items.push(speaker);
    });
    return returned_data;
}

export async function loader ({params}) {

    let staticData = await staticDataJS;
    let lang = 'cs';
    if( params.hasOwnProperty('lang') ){
        if( params.lang == 'en' ){
            lang = params.lang;
            staticData = await staticDataJSEN;
        }
    }
    let textConfigLocal = textConfig[lang];
    try {
        let content = await fetchApi({ type: 'speakers' , lang: lang });
        content = prepareData(content,textConfigLocal);
        content.textConfig = textConfigLocal;
        return { static_data: staticData.pages.speakerspage, content_data: content};
    } catch (e){
        return e;
    }
}

export default function Speaker( {data} ) {

    useEffect(() => {
        AOS.init();
    }, [])
  const dataArray = useLoaderData();
    if( dataArray.hasOwnProperty('content_data')) {
        //const staticData = dataArray.static_data;
        const content = dataArray.content_data;
        return (
            <Speakers staticData={content}/>
        )
    } else {
        return(
            <div className="App">
                <ErrorPage ManualError={dataArray}/>
            </div>
        );
    }
}