import React from "react";

import SpeakerItem from "./SpeakerItem";

export default function Speakers( { staticData } ) {
  return(
    <section id="speakers" className="section-speakers">
    <div className="container">
      <div className="speakers-wrapper">
        <h2>{ staticData.title}</h2>
        <p dangerouslySetInnerHTML={{__html:staticData.text }}></p>

        <ul className="speakers-list">
          { staticData.hasOwnProperty('items') && staticData.items.map( (item, index ) => {
            item.delay = (index%3)*300;
            return <SpeakerItem key={ index } staticData={ item } />
          }) }
        </ul>

          
        { staticData.text_bottom ? <p style={{marginTop: "60px"}}>{staticData.text_bottom}</p> : false }
      </div>
    </div>
    </section>
  )
}